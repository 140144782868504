<script>
import { FilterMatchMode } from "primevue/api";
import { defineComponent } from "vue";
import FilterModal from "../../Utils/Filter.vue";
import ProgressModal from "../../Utils/Progress.vue";
import CollaboratorModal from "./CollaboratorModal.vue";
import CollaboratorRouter from "@/api/routers/Collaborator";
export default defineComponent({
  components: {
    FilterModal,
    ProgressModal,
    CollaboratorModal,
  },
  data() {
    return {
      loading: false,
      pagination: {},
      pageRows: 10,
      filterModal: {
        show: false,
        showFilters: ["people", "departments", "companies", "general"],
        selectedFilters: {},
      },
      hasFilters: false,
      collaborators: {
        records: [],
        totalRecords: 0,
        selection: [],
      },
      collaboratorModal: {
        show: false,
        save: false,
        valid: false,
        progress: false,
        action: "create",
        title: null,
        collaborator: {},
      },
      filters: {
        name: {
          value: "",
          matchMode: "contains",
        },
      },
      CollaboratorApi: null,
    };
  },
  created() {
    this.CollaboratorApi = new CollaboratorRouter();
    this.FilterInit();
  },
  mounted() {
    this.pageRows = this.$refs.dt.rows;
    this.LoadCollaborators();
  },
  methods: {
    ShowCollaboratorModal(action, slot) {
      this.collaboratorModal.action = action;
      this.collaboratorModal.collaborator =
        action == "update" ? { ...slot } : null;
      this.collaboratorModal.title = `${
        action == "create" ? "Cadastrar" : "Atualizar"
      } colaborador`;
      this.collaboratorModal.show = true;
    },
    Valid(event) {
      event
        ? (this.collaboratorModal.valid = true)
        : (this.collaboratorModal.valid = false);
    },
    CollaboratorModalResetState() {
      this.collaboratorModal.show = false;
      this.collaboratorModal.valid = false;
      this.collaboratorModal.save = false;
      this.collaboratorModal.collaborator = {};
      this.LoadCollaborators();
    },
    CollaboratorsRemove() {
      this.$confirm.require({
        header: "Excluir",
        message:
          "Ao realizar a exclusão não será possível desfazer a operação, deseja continuar?",
        icon: "far fa-question-circle",
        acceptLabel: "Sim",
        rejectLabel: "Não",
        acceptClass: "p-button-danger",
        accept: () => {
          this.CollaboratorApi.Remove(
            this.collaborators.selection.map((c) => {
              return c.id;
            })
          )
            .then(() => {
              this.$toast.add({
                severity: "success",
                summary: "Sucesso",
                detail: "Operação realizada com sucesso",
                life: 3000,
              });
            })
            .finally(this.LoadCollaborators());
        },
      });
    },
    CollaboratorStatus(eventsSlot) {
      try {
       eventsSlot.sort(function (a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      return eventsSlot[0].collaboratorEvent.description;
      }
      catch (e) {
        console.log(e)
        return ""
      }
    },
    LoadCollaborators() {
      this.loading = true;
      setTimeout(() => {
        this.CollaboratorApi.Search(JSON.stringify(this.pagination))
          .then((resp) => {
            this.collaborators.records = resp.collaborators;
            this.collaborators.totalRecords = resp.totalRecords;
          })
          .finally((this.loading = false));
      }, Math.random() * 1000 + 250);
    },
    CloseFilterModal() {
      this.filterModal.show = false;
    },
    ShowFilterModal() {
      this.filterModal.show = true;
    },
    MakeFilter(filter) {
      this.filterModal.selectedFilters = Object.assign(
        {},
        this.filterModal.selectedFilters,
        filter
      );
      this.filters.global = Object.assign({}, this.filters.global, filter);
    },
    ClearFilters() {
      this.filterModal.selectedFilters = [];
      this.FilterInit();
      this.hasFilters = false;
      this.OnFilter();
    },
    FilterSearch() {
      this.filterModal.show = false;
      this.OnFilter();
    },
    OnPage(event) {
      this.pagination = event;
      this.LoadCollaborators();
    },
    OnFilter() {
      if (Object.keys(this.filterModal.selectedFilters).length > 0) {
        this.hasFilters = true;
      }
      this.pagination.filters = this.filters;
      this.LoadCollaborators();
    },
    FormatDepartments(departments) {
      let departmentsNames = departments.map((d) => {
        return d.name;
      });
      return departmentsNames.toString();
    },
    FilterInit() {
      this.pagination = {
        page: 1,
        rows: this.pageRows,
      };
      this.filters = {
        global: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
      };
    },
  },
});
</script>

<template>
  <Card>
    <template #title>
      <span class="font-light text-700 text-xl">Colaboradores</span>
    </template>
    <template #content>
      <Toolbar class="h-6rem">
        <template v-slot:start>
          <div class="lg:mt-0 sm:mt-2 flex flex-row" style="gap: 0.5rem">
            <Button
              icon="fas fa-plus"
              class="p-button-rounded p-button-success p-button-outlined p-button-raised"
              @click="ShowCollaboratorModal('create')"
              :disabled="loading"
              title="Adicionar novo colaborador"
            />
            <Button
              icon="fas fa-trash"
              class="p-button-rounded p-button-danger p-button-outlined p-button-raised"
              @click="CollaboratorsRemove()"
              :disabled="collaborators.selection.length == 0"
              title="Excluir colaborador(es) selecionado(s)"
            />
          </div>
        </template>
        <template v-slot:end>
          <div class="lg:mt-0 sm:mt-2 flex flex-row" style="gap: 0.5rem">
            <Button
              icon="fas fa-redo-alt"
              class="p-button-rounded p-button-info p-button-outlined p-button-raised"
              @click="LoadCollaborators()"
              :disabled="loading"
              title="Atualizar lista"
            />
            <div class="relative">
              <Button
                icon="fas fa-filter"
                class="p-button p-button-rounded p-button-info p-button-outlined p-button-raised"
                @click="ShowFilterModal()"
                :disabled="loading"
                title="Escolher filtros"
              />
              <Button
                v-if="hasFilters"
                icon="fas fa-broom"
                class="p-button-rounded p-button-raised clear-filter"
                title="Limpar filtros"
                @click="ClearFilters()"
                :disabled="loading"
              />
            </div>
          </div>
        </template>
      </Toolbar>
    </template>
    <template #footer>
      <DataTable
        ref="dt"
        :value="collaborators.records"
        dataKey="id"
        :paginator="true"
        :rows="10"
        v-model:filters="filters"
        :loading="loading"
        :totalRecords="collaborators.totalRecords"
        @page="OnPage($event)"
        @filter="OnFilter($event)"
        @sort="onSort($event)"
        lazy="true"
        v-model:selection="collaborators.selection"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 20, 50, 100, 300]"
        currentPageReportTemplate="Exibindo de {first} a {last} de {totalRecords} entradas"
        responsiveLayout="scroll"
        class="p-datatable-sm"
      >
        <template #header />
        <Column
          selectionMode="multiple"
          headerStyle="width:.2%; min-width:1rem;"
        />
        <Column
          field="id"
          header="ID"
          :sortable="false"
          headerStyle="width:.30%; min-width:2rem;"
        >
          <template #body="slotProps">
            <span class="p-column-title">ID</span>
            <span>{{ slotProps.data?.id }}</span>
          </template>
        </Column>
        <Column
          field="employerRegistrationNumber"
          header="Matricula"
          :sortable="false"
          headerStyle="width:0.2%; min-width:2rem;"
        >
          <template #body="slotProps">
            <span class="p-column-title">Matricula</span>
            <span>{{ slotProps.data?.employerRegistrationNumber }}</span>
          </template>
        </Column>
        <Column
          field="name"
          header="Nome"
          :sortable="false"
          headerStyle="width:5%; min-width:2rem;"
        >
          <template #body="slotProps">
            <span class="p-column-title">Nome</span>
            <span>{{ slotProps.data?.person?.name }}</span>
          </template>
        </Column>
        <Column
          field="company"
          header="Empresa"
          :sortable="false"
          headerStyle="width:3%; min-width:2rem;"
        >
          <template #body="slotProps">
            <span class="p-column-title">Empresa</span>
            <span>{{ slotProps.data?.company?.name }}</span>
          </template>
        </Column>
        <Column
          field="departments"
          header="Departamentos"
          :sortable="false"
          headerStyle="width:3%; min-width:2rem;"
        >
          <template #body="slotProps">
            <span class="p-column-title">Departamentos</span>
            <span>{{ FormatDepartments(slotProps.data?.departments) }}</span>
          </template>
        </Column>
        <Column
          field="status"
          header="Status"
          :sortable="false"
          headerStyle="width:3%; min-width:2rem;"
        >
          <template #body="slotProps">
            <span class="p-column-title">Status</span>
            <span>{{ CollaboratorStatus(slotProps.data?.events) }}</span>
          </template>
        </Column>
        <Column headerStyle="width: 2%; min-width:5rem;">
          <template #body="slotProps">
            <div class="flex flex-column">
              <Button
                icon="fas fa-pencil"
                class="p-button-rounded p-button-sm p-button-warning p-button-outlined p-button-raised mr-2 action-button"
                title="Editar pessoa"
                @click="ShowCollaboratorModal('update', slotProps.data)"
              />
            </div>
          </template>
        </Column>
      </DataTable>
    </template>
  </Card>
  <Dialog
    v-model:visible="collaboratorModal.show"
    :header="
      !collaboratorModal.progress ? collaboratorModal.title : 'Aguarde...'
    "
    :modal="true"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '30vw' }"
    :maximizable="false"
    :closable="!collaboratorModal.progress"
    @hide="CollaboratorModalResetState()"
  >
    <CollaboratorModal
      v-if="!collaboratorModal.progress"
      :collaborator="collaboratorModal.collaborator"
      :action="collaboratorModal.action"
      :save="collaboratorModal.save"
      @valid="Valid($event)"
      @progress="collaboratorModal.progress = $event"
      @close="collaboratorModal.show = false"
    />
    <ProgressModal v-if="collaboratorModal.progress" />
    <template #footer>
      <div
        class="flex flex-row justify-content-between"
        v-if="!collaboratorModal.progress"
      >
        <Button
          label="Cancelar"
          iconPos="right"
          icon="pi pi-times"
          autofocus
          @click="collaboratorModal.show = false"
          class="w-12rem"
        />
        <Button
          label="Salvar"
          iconPos="right"
          icon="pi pi-check"
          autofocus
          :disabled="!collaboratorModal.valid"
          @click="collaboratorModal.save = true"
          class="w-12rem"
        />
      </div>
    </template>
  </Dialog>
  <Dialog
    v-model:visible="filterModal.show"
    header="Filtro avançado"
    :modal="true"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '30vw' }"
    :maximizable="false"
  >
    <FilterModal
      @filterSelection="MakeFilter($event)"
      :showFilters="filterModal.showFilters"
      :currentFilters="filterModal.selectedFilters"
    />
    <template #footer>
      <div class="flex flex-row justify-content-between">
        <Button
          label="Cancelar"
          iconPos="right"
          icon="pi pi-times"
          autofocus
          @click="CloseFilterModal()"
          class="w-12rem"
        />
        <Button
          label="Pesquisar"
          iconPos="right"
          icon="pi pi-search"
          autofocus
          @click="FilterSearch()"
          class="w-12rem"
        />
      </div>
    </template>
  </Dialog>
</template>
