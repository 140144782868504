<script>
import { defineComponent } from 'vue';
import StepControls from '../Components/StepControls.vue';
export default defineComponent({
    components: {
        StepControls
    },
    data() {
        return {
            documentType: '',
            form: { formData: { documentType: { value: null, mimeTypes: null } }, pageIndex: 0 },
            disableNextButton: true,
            hideBackButton: true,
            mimeTypes: ''
        };
    },
    methods: {
        buttonImg(slug) {
            return this.$appState.darkTheme ? `images/envelopes/${slug}-dark.svg` : `images/envelopes/${slug}.svg`;
        },
        setType(docType) {
            if (docType == 'payroll' || docType == 'ir') {
                this.mimeTypes = 'application/pdf';
            } else {
                this.mimeTypes = 'application/pdf, image/*';
            }
            this.documentType = docType;
            this.form.formData.documentType = {
                value: this.documentType,
                mimeTypes: this.mimeTypes
            };
            this.disableNextButton = false;
        }
    }
});
</script>

<template>
    <ToolTip />
    <div class="md:h-30rem lg:h-22rem xl:30-rem">
        <div class="container h-full flex align-items-center justify-content-center">
            <Button style="border: 0rem; background: linear-gradient(150deg, #3498db, #0c3550)" class="p-button-raised p-button-info mr-3 shadow-6 h-15rem w-15rem" @click="setType('payroll')" :disabled="this.documentType == 'payroll'">
                <div class="w-full flex flex-column align-items-center">
                    <img :src="buttonImg('payroll')" class="mb-4 ml-3 w-6rem" />
                    <span class="font-bold text-lg text-white">Recibo de pagamento</span>
                </div>
            </Button>
            <Button style="border: 0rem; background: linear-gradient(150deg, #3498db, #0c3550)" class="p-button-raised mr-3 shadow-6 h-15rem w-15rem" v-tooltip.bottom="'Opção indisponível no momento'" :disabled="this.documentType == 'ir'">
                <div class="w-full flex flex-column align-items-center">
                    <img :src="buttonImg('ir')" class="mb-4 ml-3 w-6rem" />
                    <span class="font-bold text-lg text-white">Imposto de Renda</span>
                </div>
            </Button>
            <!-- @click="setType('other')" -->
            <!-- <Button class="p-button-raised p-button-info mr-3 shadow-6 h-15rem w-15rem"  :disabled="this.documentType == 'other'"
            v-tooltip.bottom="'Opção indisponível no momento'" >
                <div class="w-full flex flex-column align-items-center">
                    <img :src="buttonImg('other')" class="mb-4 w-6rem" />
                    <span class="font-bold">Outro documento</span>
                </div>
            </Button>  -->
        </div>
    </div>
    <StepControls :form="this.form" :disableNextButton="this.disableNextButton" :hideBackButton="this.hideBackButton" />
</template>

<style scoped></style>
