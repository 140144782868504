import List from './List/List'
import Envelopes from './Envelopes'
const router = [
    {
        path: "/envelopes",
        name: "envelopes",
        component: Envelopes,
        children: [
            {
                path: "list",
                component: List
            },
        ]
    },
];

export default router;