import AxiosInstance from '@/services/AxiosInstance'

const statusEndpoint = "/server/status"

export default class StatusRouter {
    async Status() {
        return await AxiosInstance.get(statusEndpoint)
            .then(resp => resp.data)
            .then(d => d.data)
    }
}