<script>
import VuePdfEmbed from "vue-pdf-embed";
import { defineComponent } from "vue";
export default defineComponent({
  props: ["pdf", "password", "rotate", "zoom"],
  emits: ["wrongPassword", "rendered"],
  components: {
    VuePdfEmbed,
  },
  data() {
    return {
      pdfProtected: false,
      rotation: 0,
    };
  },
  methods: {
    DocumentRendered() {
      this.$emit("rendered", true)
    },  
    HandlerPdfPassword(callback, retry) {
      this.pdfProtected = true;
      if (!retry) {
        callback(this.password);
      } else {
        this.$emit("wrongPassword", true);
      }
    },
  },
  watch: {
    rotate: function () {
      this.rotation == 270 ? (this.rotation = 0) : (this.rotation += 90);
    },
  },
});
</script>

<template>
  <div class="field">
    <div class="flex container mb-2" v-if="pdfProtected">
      <i class="fas fa-lock fa-lg text-green-500"></i>
      <span class="ml-2">Este documento está protegido por senha</span>
    </div>
    <VuePdfEmbed
      :source="pdf"
      :width="zoom"
      :rotation="rotation"
      @password-requested="HandlerPdfPassword"
      @rendered="DocumentRendered()"
    />
  </div>
</template>
