import AxiosInstance from "@/services/AxiosInstance";

const totp = '/totp'
const manager = '/manager'
const totpManager = `${totp}${manager}`

export default class PersonRouter {
    async Configure() {
        return await AxiosInstance.post(`${totpManager}/configure`)
            .then(resp => resp.data)
            .then(d => d.data)
    }
    async Enabled() {
        return await AxiosInstance.get(`${totpManager}/enabled`)
            .then(resp => resp.data)
            .then(d => d.data)
    }
    async Validate(payload) {
        return await AxiosInstance.post(`${totpManager}/validate`, payload)
            .then(resp => resp?.data)
            .then(d => d?.data)
    }

    async Authenticate(payload) {
        return await AxiosInstance.post(`${totp}/auth`, payload)
            .then(resp => resp?.data)
            .then(d => d?.data)
    }
}