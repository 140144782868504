import AxiosInstance from '@/services/AxiosInstance';

const loginEndpoint = '/auth/login';
const logoutEndpoint = '/auth/logout';
const refreshEndpoint = '/auth/refresh';
const authenticatedUserInfo = '/auth/info';
const forgotPasswordEndpoint = '/auth/password/forgot';
const resetPasswordEndpoint = '/auth/password/reset';
const validateResetToken = '/auth/password/validate-rtk';

export default class AuthRouter {
    async login(payload) {
        return await AxiosInstance.post(loginEndpoint, payload).then((resp) => resp?.data);
    }
    async checkResetToken(token) {
        return await AxiosInstance.get(`${validateResetToken}/${token}`).then((resp) => resp.data);
    }
    async forgot(payload) {
        return await AxiosInstance.post(forgotPasswordEndpoint, payload).then((resp) => resp?.data);
    }
    async reset(payload) {
        return await AxiosInstance.post(resetPasswordEndpoint, payload).then((resp) => resp?.data);
    }
    async logout() {
        return await AxiosInstance.get(logoutEndpoint).then((resp) => resp?.data);
    }
    async refresh(payload) {
        return await AxiosInstance.post(refreshEndpoint, payload).then((resp) => resp?.data);
    }
    async authInfo() {
        return await AxiosInstance.get(authenticatedUserInfo).then((resp) => resp?.data);
    }
}
