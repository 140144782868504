import AxiosInstance from '@/services/AxiosInstance'

const documentEndpoint = '/documents'
const payrollEndpoint = `${documentEndpoint}/payroll`
const processPayrollEndpoint = `${payrollEndpoint}/process`
const processPayrollStatusEndpoint = `${payrollEndpoint}/status?identifier=`
const documentsFind = `${documentEndpoint}/find`
const viewDocumentEndpoint = `${documentEndpoint}/view`

export default class DocumentRouter {
    async Search(filters) {
        return await AxiosInstance.get(`${documentEndpoint}?search=${filters}`)
            .then(resp => resp.data)
            .then(d => d.data)
    }
    async Export(filters, removeProtection) {
        return await AxiosInstance.get(`${documentEndpoint}/export?filter=${filters}&removeProtection=${removeProtection}`)
            .then(resp => resp.data)
    }
    async Download(hash) {
        return await AxiosInstance.get(`${documentEndpoint}/export/download?hash=${hash}`, { responseType: 'blob' }).then((resp) => {
            const contentDisposition = resp.headers.get("Content-Disposition");
            let filename = "export.zip";
            if (contentDisposition) {
                const match = contentDisposition.match(/filename="(.+)"/);
                if (match) {
                    filename = match[1];
                }
            }

            const link = document.createElement("a");
            link.href = URL.createObjectURL(new Blob([resp.data]));
            link.setAttribute("download", filename);
            link.style.display = "none";

            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(link.href);
        })
    }
    async FindAll() {
        return await AxiosInstance.get(documentEndpoint)
            .then(resp => resp.data)
            .then(d => d.data)
    }
    async Update(id, payload) {
        return await AxiosInstance.put(`${documentEndpoint}/${id}`, payload)
            .then(resp => resp.data)
            .then(d => d.data)
    }
    async Find(filters, slug) {
        return await AxiosInstance.get(`${documentsFind}?slug=${slug}&search=${filters}`)
            .then(resp => resp.data)
            .then(d => d.data)
    }
    async PayrollProcess(payload) {
        return await AxiosInstance.post(processPayrollEndpoint, payload)
            .then(resp => resp.data)
            .then(d => d.data)

    }
    async PayrollProcessStatus(identifier) {
        return await AxiosInstance.get(`${processPayrollStatusEndpoint}${identifier}`)
            .then(resp => resp.data)
            .then(d => d.data)
    }
    async View(format, payload) {
        return await AxiosInstance.post(`${viewDocumentEndpoint}?format=${format}`, payload)
            .then(resp => resp.data)
            .then(d => d.data)
    }
    async Remove(payload) {
        return await AxiosInstance.delete(documentEndpoint, { data: payload })
            .then(resp => resp.data)
    }
}