import AxiosInstance from "@/services/AxiosInstance";

const envelopeEventsEndpoint  = `/envelope-events`

export default class EnvelopeEventsRouter {
    async Search(filterParams) {
        return await AxiosInstance.get(`${envelopeEventsEndpoint}?search=${filterParams}`)
            .then(resp => resp.data)
            .then(d => d.data)
    } 

    async FindAll() {
        return await AxiosInstance.get(envelopeEventsEndpoint)
            .then(resp => resp.data)
            .then(d => d.data)
    }

    async Create(payload) {
        return await AxiosInstance.post(envelopeEventsEndpoint, payload)
            .then(resp => resp.data)
            .then(d => d.data)
    } 
}