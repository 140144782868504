<script>
import { defineComponent } from "vue";
import MenuRouter from "@/api/routers/Menu";
import MenuTypesRouter from "@/api/routers/MenuTypes";
export default defineComponent({
  props: ["action", "menu", "save"],
  emits: ["valid", "close", "progress"],
  data() {
    return {
      menuForm: {
        label: null,
        icon: null,
        action: null,
        showOrder: 1,
      },
      menuTypes: {
        records: [],
        selectedMenuType: null,
        loading: false,
      },
      menus: {
        records: [],
        selectedMenu: null,
        loading: false,
      },
      MenuRouter: null,
      MenuTypesRouter: null,
    };
  },
  created() {
    this.MenuApi = new MenuRouter();
    this.MenuTypesApi = new MenuTypesRouter();
  },
  async mounted() {
    await this.LoadMenus();
    await this.LoadMenuTypes();
    this.GetAction();
  },
  methods: {
    async LoadMenus() {
      this.menus.loading = true;
      this.menus.records = await this.MenuApi.FindAll()
        .then((resp) => {
          return resp?.menus.map((m) => {
            return {
              label: m.label,
              value: m.id,
            };
          });
        })
        .finally((this.menus.loading = false));
    },
    async LoadMenuTypes() {
      this.menuTypes.loading = true;
      this.menuTypes.records = await this.MenuTypesApi.FindAll()
        .then((resp) => {
          return resp?.menuTypes.map((m) => {
            return {
              label: m.label,
              value: m.id,
            };
          });
        })
        .finally((this.menuTypes.loading = false));
    },

    GetAction() {
      if (this.action == "update") {
        this.menuForm = { ...this.menu };
        this.menus.selectedMenu = this.menu?.parentId;
        this.menuTypes.selectedMenuType = this.menu.menuTypeId;
      }
    },
    Valid() {
      if (
        this.menuForm.label?.length > 0 &&
        this.menuTypes.selectedMenuType != null
      ) {
        this.$emit("valid", true);
      } else {
        this.$emit("valid", false);
      }
    },
    Save() {
      switch (this.action) {
        case "create":
          this.CreateMenu();
          break;
        case "update":
          this.UpdateMenu();
          break;
      }
    },
    async UpdateMenu() {
      this.$emit("progress", true);
      setTimeout(async () => {
        await this.MenuApi.Update(this.GetPayload(), this.menuForm.id)
          .then(() => {
            this.$toast.add({
              severity: "success",
              summary: "Sucesso",
              detail: "Operação realizada com sucesso",
              life: 3000,
            });
          })
          .finally(() => {
            this.$emit("progress", false);
            this.$emit("close");
          });
      }, 2000);
    },
    async CreateMenu() {
      this.$emit("progress", true);
      setTimeout(async () => {
        await this.MenuApi.Create(this.GetPayload())
          .then(() => {
            this.$toast.add({
              severity: "success",
              summary: "Sucesso",
              detail: `Operação realizada com sucesso`,
              life: 2000,
            });
          })
          .finally(() => {
            this.$emit("progress", false);
            this.$emit("close");
          });
      }, 2000);
    },
    GetPayload() {
      let payload = {};
      try {
        payload = {
          label: this.menuForm.label,
          icon: this.menuForm?.icon,
          menuTypeId: this.menuTypes.selectedMenuType,
          action: this.menuForm.action,
          showOrder: this.menuForm.showOrder,
          parentId: this.menus.selectedMenu,
        };
        return payload;
      } catch {
        this.$toast.add({
          severity: "error",
          summary: "Erro critico",
          detail: "Ocorreu um erro ao obter as informações necessárias",
          life: 3000,
        });
      }
    },
  },
  watch: {
    save: function () {
      this.Save();
    },
  },
});
</script>

<template>
  <div class="flex flex-column field">
    <label for="name">Nome:</label>
    <InputText
      id="name"
      v-model="menuForm.label"
      placeholder="Nome de exibição do menu"
      @input="Valid()"
    />
  </div>
  <div class="flex flex-column field">
    <label for="txId">Icone:</label>
    <InputText
      id="icon"
      v-model="menuForm.icon"
      placeholder="Ícone do menu"
      @input="Valid()"
    />
  </div>
  <div class="flex flex-column field">
    <label for="ceo">Tipo:</label>
    <Dropdown
      id="ceo"
      v-model="menuTypes.selectedMenuType"
      optionLabel="label"
      optionValue="value"
      placeholder="Selecione o tipo de menu"
      emptyFilterMessage="Sem resultados"
      emptyMessage="Sem resultados"
      :filter="true"
      :options="menuTypes.records"
      :loading="menuTypes.loading"
      @change="Valid()"
    />
  </div>
  <div class="flex flex-column field">
    <label for="txId">Ação:</label>
    <InputText
      id="action"
      v-model="menuForm.action"
      placeholder="Ação do menu"
      @input="Valid()"
    />
  </div>
  <div class="flex flex-column field">
    <label for="txId">Ordem:</label>
    <InputNumber
      id="showOrder"
      mode="decimal"
      showButtons
      :min="1"
      v-model="menuForm.showOrder"
      placeholder="Ordem de exibição do menu"
      @input="Valid()"
    />
  </div>
  <div class="flex flex-column field">
    <label for="ceo">Pai:</label>
    <Dropdown
      id="parent"
      v-model="menus.selectedMenu"
      optionLabel="label"
      optionValue="value"
      placeholder="Selecione o menu raiz deste menu"
      emptyFilterMessage="Sem resultados"
      emptyMessage="Sem resultados"
      :filter="true"
      :options="menus.records"
      :loading="menus.loading"
      @change="Valid()"
    />
  </div>

</template>
