import { createRouter, createWebHashHistory } from 'vue-router';
import { defineAsyncComponent } from 'vue';
import Guard from '@/services/Guard';
import LoadApplicationData from '@/services/ApplicationData';
import Token from '@/services/Token';

import App from '@/App.vue';
import Dashboard from '@/components/Dashboard.vue';
import Login from '@/components/Auth/Login.vue';
import ForgotPassword from '@/components/Auth/ForgotPassword.vue';
import ChangePassword from '@/components/Auth/ChangePassword.vue';
import NotFound from '@/components/Pages/NotFound.vue';
import AccessDenied from '@/components/Pages/Access.vue';
import Error from '@/components/Pages/Error.vue';
import Loading from '@/components/Pages/Loading.vue';
import Maintenance from '@/components/Pages/Maintenance.vue';
import QuickValidation from '@/components/Pages/QuickValidation.vue';

import DocumentRouters from '@/components/Documents/document.router';
import EnvelopeRouters from '@/components/Envelopes/envelope.router';
import AdminRouters from '@/components/Admin/admin.router';
import EmployeeRouters from '@/components/Employee/employee.router';

const asyncComponent = (loader) => defineAsyncComponent({
    loader,
    delay: 5000,
    errorComponent: Error,
    loadingComponent: Loading,
    timeout: 5000,
});

const routes = createRouter({
    history: createWebHashHistory(),
    routes: [
        {
            path: '',
            component: App,
            children: [
                { path: '', name: 'dashboard', component: Dashboard, meta: { middleware: CheckAccessMiddleware } },
                { 
                    path: 'documents', name: 'documents',
                    component: asyncComponent(() => import('@/components/Documents/Documents')),
                    children: DocumentRouters,
                    meta: { middleware: CheckAccessMiddleware, ignore: ['/documents/upload/content', '/documents/upload/recipients'] }
                },
                { 
                    path: 'employee', name: 'employee',
                    component: asyncComponent(() => import('@/components/Employee/Employee')),
                    children: EmployeeRouters 
                },
                { 
                    path: 'envelopes', name: 'envelopes',
                    component: asyncComponent(() => import('@/components/Envelopes/Envelopes')),
                    children: EnvelopeRouters,
                    meta: { middleware: CheckAccessMiddleware }
                },
                { 
                    path: 'admin', name: 'administration',
                    component: asyncComponent(() => import('@/components/Admin/Admin')),
                    children: AdminRouters,
                    meta: { middleware: CheckAccessMiddleware }
                }
            ]
        },
        { path: '/:pathMatch(.*)*', name: 'notfound', component: NotFound },
        { path: '/pages/access', name: 'accessDenied', component: AccessDenied },
        { path: '/pages/error', name: 'error', component: Error },
        { path: '/pages/loading', name: 'loading', component: Loading },
        { path: '/pages/maintenance', name: 'maintenance', component: Maintenance },
        { path: '/quick/pending-envelopes', name: 'quick-validation', component: QuickValidation },
        { path: '/auth/login', name: 'login', component: Login },
        { path: '/auth/password/forgot', name: 'forgot', component: ForgotPassword },
        { path: '/change_password', name: 'change_password', component: ChangePassword },

    ]
});

const publicPages = ['/auth/login', '/auth/password/forgot', '/change_password'];
const alertPages = ['/pages/loading', '/pages/error', '/pages/access'];

async function CheckAccessMiddleware(context) {
    const routerAccess = await CheckRouterPermission(context.to.path);
    if (!alertPages.includes(context.to.path) && !publicPages.includes(context.to.path) && !routerAccess.hasPermission && !context.ignore.includes(context.to.path)) {
        context.next('/pages/access');
    } else {
        context.next();
    }
}

async function CheckRouterPermission(router) {
    try {
        const data = await LoadApplicationData();
        const items = data.userInfo.menus.flatMap(menu => menu.items);
        return {
            hasPermission: items.some(item => item?.action === router || item?.to === router),
            allowedMenus: items.map(item => item?.action || item?.to)
        };
    } catch {
        location.reload();
    }
}

routes.beforeEach((to, from, next) => {
    if (Token.getAuthorizationToken()) {
        if (to.fullPath !== '/') {
            const middleware = to.meta.middleware || [];
            const ignorePaths = to.meta.ignore || [];
            const context = { to, from, next, ignore: ignorePaths };
            if (!Array.isArray(middleware)) {
                return middleware(context);
            }
        } else {
            next();
        }
    }

    const authRequired = !publicPages.includes(to.path);
    const loggedIn = Guard();
    authRequired && !loggedIn ? next(publicPages[0]) : next();
});

export default routes;
