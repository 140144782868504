import {AuthToken, RefreshToken, UserInfo} from './StorageKeys'

class Token {
    getRefreshToken() {
        return localStorage.getItem(RefreshToken);
    }
    getAuthorizationToken() {
        return localStorage.getItem(AuthToken);
    }
    setAuthorizationToken(tkn) {
        localStorage.setItem(AuthToken, tkn);
    }
    setRefreshToken(rTkn) {
        localStorage.setItem(RefreshToken, rTkn);
    }
    clearLocalStorage() {
        localStorage.removeItem(AuthToken);
        localStorage.removeItem(RefreshToken);
        localStorage.removeItem(UserInfo);
    }
    jwtExpiration(tkn) {
        let b64Tkn = tkn.split('.')[1];
        let b64 = b64Tkn.replace(/-/g, '+').replace(/_/g, '/');
        let jsonPayload = decodeURIComponent(Buffer.from(b64, "base64").toString("ascii").split("").map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        return JSON.parse(jsonPayload);
    }
}
export default new Token()