<script>
import { defineComponent, computed } from 'vue';
export default defineComponent({
    data() {
        return {
            items: [
                {
                    label: 'Tipo de documento',
                    to: '/documents/upload'
                },
                {
                    label: 'Conteúdo do documento',
                    to: '/documents/upload/content'
                },
                {
                    label: 'Identificação dos documentos',
                    to: '/documents/upload/recipients'
                }
            ],
            home: { icon: 'pi pi-home', to: '/' },
            breadcrumbItems: [{ label: 'Documentos', to: this.$router.currentRoute._value }, { label: 'Novo', to: this.$router.currentRoute._value }],
            formObject: {}
        };
    },
    methods: {
        nextPage(event) {
            for (let field in event.formData) {
                this.formObject[field] = event.formData[field];
            }
            this.$router.push(this.items[event.pageIndex + 1].to);
        },
        prevPage(event) {
            this.$router.push(this.items[event.pageIndex - 1].to);
        },
        stepsComplete(event) {
            this.$router.push(`/documents/send-queue?queueId=${event}&updateTime=5000`);
        }
    },
    provide() {
        return {
            form: computed(() => this.formObject)
        };
    }
});
</script>

<template>
    <Breadcrumb :home="home" :model="breadcrumbItems" />
    <Divider />
    <Card>
        <template #title>
            <Divider />
            <Steps :model="items" :readonly="true" aria-label="Envelope Steps" />
            <Divider />
        </template>
        <template #content>
            <router-view v-slot="{ Component }" :formData="formObject" @prevPage="prevPage($event)" @nextPage="nextPage($event)" @complete="stepsComplete($event)">
                <keep-alive>
                    <component :is="Component" />
                </keep-alive>
            </router-view>
        </template>
    </Card>
</template>

<style lang="scss">
::v-deep(b) {
    display: block;
}

::v-deep(.p-card-body) {
    padding: 2rem;
}
</style>
