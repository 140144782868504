<script>
import Lottie from '../Utils/Lottie'
import Loading  from './../../layout/lotties/loading.json';
export default {
    components: {
        Lottie,
    },
    data() {
        return {
            lottie: {
                animation: Loading,
                height: 250,
                width: 250,
                speed: 1.5,
                loop: true
            },
        }
    }
}

</script>
<template>
   <div class="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden pg-background">
        <div class="flex flex-column align-items-center justify-content-center">
            <div class="col-12">
                <div class="surface-card py-6 px-8 card-effect">
                    <div class="grid flex flex-column align-items-center">
                        <span class="font-semibold text-gray-600 text-xl mb-2">Aguarde...</span>
                        <Lottie :lottieProp="this.lottie"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
