import AxiosInstance from '@/services/AxiosInstance'

const envelopeEndpoint = '/envelopes'


export default class EnvelopeRouter {
    async Search(filters){
        return await AxiosInstance.get(`${envelopeEndpoint}?search=${filters}`)
            .then(resp => resp.data)
            .then(d => d.data)
    }
    async FindByEvent(event) {
        return await AxiosInstance.get(`${envelopeEndpoint}?event=${event}`)
            .then(resp => resp.data)
            .then(d => d.data)
    }
    async LoggedInList(filters) {
        return await AxiosInstance.get(`${envelopeEndpoint}/list?search=${filters}`)
            .then(resp => resp.data)
            .then(d => d.data)
    }
    async Create(payload) {
        return await AxiosInstance.post(envelopeEndpoint, payload)
            .then(resp => resp.data)        
    }
    async Remove(id) {
        return await AxiosInstance.delete(`${envelopeEndpoint}/${id}`)
    }
    async Cancel(payload) {
        return await AxiosInstance.post(`${envelopeEndpoint}/cancel`, payload)
    }
    async Resend(payload) {
        return await AxiosInstance.post(`${envelopeEndpoint}/resend`, payload)
    }    
}