<script>
import Lottie from '../Utils/Lottie'
import Error  from './../../layout/lotties/error.json';
export default {
    components: {
        Lottie,
    },
    data() {
        return {
            lottie: {
                animation: Error,
                height: 200,
                width: 350,
                speed: 0.8,
                loop: false
            },
        }
    }
}

</script>

<template>
   <div class="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden pg-background">
        <div class="flex flex-column align-items-center justify-content-center">
            <div class="col-12">
                <div class="surface-card py-6 px-8 card-effect">
                    <div class="grid flex flex-column align-items-center">
                        <span class="font-bold text-gray-900 text-4xl mb-2">Erro crítico</span>
                        <span class="text-gray-500 text-sm mb-5">Ocorreu um erro ao processar a solicitação</span>
                        <Lottie :lottieProp="this.lottie"/>
                        <div class="col-12 mt-5 text-center">
                            <i class="pi pi-fw pi-arrow-left text-blue-500 mr-2" style="vertical-align: center"></i>
                            <router-link to="/" class="text-blue-500">Ir para o inicio</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

