<script>
import Lottie from './Lottie.vue'
import Loading  from '@/layout/lotties/loading.json';
export default {
    components: {
        Lottie,
    },
    data() {
        return {
            lottie: {
                animation: Loading,
                height: 150,
                width: 150,
                speed: 1,
                loop: true
            },
        }
    }
}
</script>

<template>
  <div class="grid flex flex-column align-items-center field">
    <Lottie :lottieProp="this.lottie" />
  </div>
</template>
