import AxiosInstance  from "@/services/AxiosInstance";

const personTypesEndpoint = '/person-types'

export default class PersonTypesRouter {
    async FindAll() {
        return await AxiosInstance.get(personTypesEndpoint)
            .then(resp => resp.data)
            .then(d => d.data)
    }
}