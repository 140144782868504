<script>
import Lottie from '../../../Utils/Lottie'
import Sending  from '@/layout/lotties/sending.json';
export default {
    components: {
        Lottie,
    },
    data() {
        return {
            lottie: {
                animation: Sending,
                height: 250,
                width: 350,
                speed: 1.3,
                loop: true
            },
        }
    }
}

</script>

<template>
    <div class="field">
        <Lottie :lottieProp="this.lottie"/>
        <ProgressBar mode="indeterminate" class="m-2" style="height: .20em"/>
    </div>
</template>