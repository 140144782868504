<script>
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  props: ["errors"],
});
</script>

<template>
  <ScrollPanel style="width: 100%; height: 400px" class="scroll">
    <div v-for="error in errors" :key="error">
      <Panel :header="error.title" :toggleable="true" class="mt-1">
        <template #icons>
          <Button class="p-panel-header-icon p-link mr-2" @click="toggle" />
        </template>
        <p>
          {{ error.description }}
        </p>
      </Panel>
    </div>
  </ScrollPanel>
</template>
