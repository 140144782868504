<script>
import { defineComponent } from 'vue';
import PayrollDocument from '../Components/PayrollDocument.vue';
import OtherDocument from '../Components/OtherDocument.vue'

export default defineComponent({
    props: ['formData'],
    components: {
        PayrollDocument,
        OtherDocument
    },
    computed: {
        isPayrollDocument() {
            return (this.formData.documentType.value == 'payroll')
        }
    }

});

</script>

<template>
    <div>
        <PayrollDocument :formData="formData" v-if="isPayrollDocument"/>
        <OtherDocument :formData="formData" v-else/>
        </div>
</template>