<script>
import { defineComponent } from 'vue';
import Upload from '@/layout/lotties/upload.json'
import Success from '@/layout/lotties/success2.json'
import Lottie from '../../../Utils/Lottie.vue'
import FileUpload from 'primevue/fileupload'
import Token from '../../../../services/Token'
import AxiosInstance from '@/services/AxiosInstance'


export default defineComponent({
    props: ['mimeTypes'],
    emits: ['uploadComplete', 'uploading', 'uploadError'],
    components: {
        Lottie,
        FileUpload
    },
    data() {
        return {
            lottie: {
                animation: Upload,
                height: 140,
                width: 250,
                speed: 1,
                loop: false
            },
            uploadedFiles: [],
            files: [],
            totalSize: 0,
            totalSizePercent: 0,
            uploadUrl: '',
            uploadContainerText: 'Arraste e solte um documento para fazer upload'
        }
    },
    mounted() {
        const BASE_URL = AxiosInstance.defaults.baseURL
        this.uploadUrl = `${BASE_URL}/documents/upload`
    },
    methods: {
        handlerUpload(event) {
            event.xhr.setRequestHeader("Authorization", `Bearer ${Token.getAuthorizationToken()}`)
        },
        handlerError(event) {
            this.$emit('uploadError', event);
        },
        onSelectedFiles(event) {
            this.files = event.files;
            this.files.forEach((file) => {
                this.totalSize += parseInt(this.formatSize(file.size));
            });
        },
        onUploadComplete(event) {
            this.totalSize = 0;
            this.totalSizePercent = 0;
            this.lottie.animation = Success
            this.lottie.speed = 0.5
            this.lottie.height = 120
            this.uploadContainerText = 'O arquivo foi enviado com sucesso'
            this.$emit('uploadComplete', event);
        },
        onUploading() {
            this.$emit('uploading')
        },
        formatSize(bytes) {
            if (bytes === 0) {
                return '0 B';
            }

            let k = 1000,
                dm = 3,
                sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
                i = Math.floor(Math.log(bytes) / Math.log(k));

            return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
        }
    }
});
</script>

<template>
    <FileUpload name="file" mode="advanced" :file-limit="1" @upload="onUploadComplete($event)" :url="uploadUrl"
        :with-credentials="false" :multiple="false" :choose-label="'Selecionar'" :show-cancel-button="true"
        :accept="mimeTypes" :invalid-file-type-message="'Arquivo inválido ou não aceito'"
        :invalid-file-size-message="'Não é possível enviar arquivos com mais de 100MB'" :maxFileSize="1000000000"
        v-on:before-send="handlerUpload" v-on:before-upload="onUploading" @select="onSelectedFiles"
        v-on:error="handlerError">
        <template #empty>
            <div class="flex align-items-center justify-content-center flex-column">
                <Lottie :lottieProp="this.lottie" />
                <p class="mt-4 mb-0">{{ uploadContainerText }}</p>
            </div>
        </template>
    </FileUpload>
</template>