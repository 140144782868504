<script>
import Lottie from '../../../Utils/Lottie'
import Success  from '@/layout/lotties/success2.json';
export default {
    components: {
        Lottie,
    },
    data() {
        return {
            lottie: {
                animation: Success,
                height: 250,
                width: 220,
                speed: 0.7,
                loop: false
            },
        }
    }
}

</script>

<template>
    <div class="field justify-content-center">
        <Lottie :lottieProp="this.lottie"/>
        <span class="text-md text-center text-800 font-bold">Consulte a página de status no menu envelopes para mais detalhes</span>
    </div>
</template>