import AuthRouter from '../api/routers/Auth'
import Token from './Token'

const authRouter = new AuthRouter()

class Auth {
    login(user) {
        let payload = { username: user.username, password: user.password }
        return authRouter.login(payload)
            .then((resp) => {
                    Token.setAuthorizationToken(resp.data?.login.accessToken);
                    Token.setRefreshToken(resp.data?.login?.refreshToken);
            });
    }
    logout() {
        authRouter.logout().
            finally(() => {
                Token.clearLocalStorage();
            })

    }
    refresh() {
        let refreshTkn = Token.getRefreshToken()
        let payload = { refreshToken: refreshTkn }
        authRouter.refresh(payload)
    }
}

export default new Auth();