import AxiosInstance from '@/services/AxiosInstance'

const collaboratorWorkScheduleEndpoint = '/collaborator-work-schedule'

export default class CollaboratorWorkScheduleRouter{
    async FindAll() {
        return await AxiosInstance.get(collaboratorWorkScheduleEndpoint)
        .then(resp => resp.data)
        .then(d => d.data)
    }
}