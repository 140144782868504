<script>
import { defineComponent } from 'vue';

export default defineComponent({
    props: ['hideBackButton', 'hideNextButton', 'showFinishButton', 'disableNextButton', 'disableBackButton', 'emitOnly', 'form'],
    emits: ['nextClicked', 'backClicked', 'finishClicked'],
    methods: {
        backPage() {
            if (this.emitOnly) {
                this.$emit('backClicked')
                return
            }
            this.$parent.$emit('prevPage', this.form);
        },
        nextPage() {
            if (this.emitOnly) {
                this.$emit('nextClicked')
                return
            }
            this.$parent.$emit('nextPage', this.form);
        },
        finish() {
            this.$emit('finishClicked', this.form);
        }
    }
});

</script>

<template>
    <div class="mt-6">
        <div class="flex justify-content-end flex-wrap card-container w-full">
            <Button v-if="!hideBackButton" :disabled="disableBackButton" class="mr-2" label="Voltar"
                icon="fas fa-angle-left" iconPos="left" @click="backPage" />
            <Button v-if="!hideNextButton" :disabled="disableNextButton" class="ml-2 mr-2" label="Próximo"
                icon="fas fa-angle-right" iconPos="right" @click="nextPage" />
            <Button v-if="showFinishButton" class="ml-2" label="Ir para fila de processamento" @click="finish()" />

        </div>
    </div>
</template>