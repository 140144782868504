import AxiosInstance from "@/services/AxiosInstance";

const departmentEndpoint = "/departments"

export default class DepartmentRouter {
    async Search(filterParams) {
        return await AxiosInstance.get(`${departmentEndpoint}?search=${filterParams}`)
            .then(resp => resp.data)
            .then(d => d.data)
    }
    async Create(payload) {
        return await AxiosInstance.post(departmentEndpoint, payload)
            .then(resp => resp.data)
            .then(d => d.data)
    }
    async Update(payload, id) {
        return await AxiosInstance.put(`${departmentEndpoint}/${id}`, payload)
            .then(resp => resp.data)
            .then(d => d.data)
    }
    async SetManager(payload) {
        return await AxiosInstance.patch(departmentEndpoint, payload)
            .then(resp => resp.data)
            .then(d => d.data)
    }
    async FindAll() {
        return await AxiosInstance.get(departmentEndpoint)
            .then(resp => resp.data)
            .then(d => d.data)
    }
    async Remove(payload) {
        return await AxiosInstance.delete(departmentEndpoint, { data: payload })
            .then(resp => resp.data)
            .then(d => d.data)
    }
}