<script>
import { defineComponent } from "vue";
import CollaboratorRouter from "@/api/routers/Collaborator";
import DepartmentRouter from "@/api/routers/Department";
export default defineComponent({
  props: ["action", "department", "save"],
  emits: ["valid", "close", "progress"],
  data() {
    return {
      departmentForm: {
        name: null,
        managerId: null,
      },
      collaborators: {
        records: [],
        selectedCollaborator: null,
        loading: false,
      },
      CollaboratorApi: null,
      DepartmentApi: null,
    };
  },
  created() {
    this.CollaboratorApi = new CollaboratorRouter();
    this.DepartmentApi = new DepartmentRouter();
  },
  async mounted() {
    await this.LoadCollaborators();
    this.GetAction();
  },
  methods: {
    async LoadCollaborators() {
      this.collaborators.loading = true;
      this.collaborators.records = await this.CollaboratorApi.FindAll()
        .then((resp) => {
          return resp?.collaborators.map((c) => {
            return {
              label: c?.person?.name,
              value: c?.id,
            };
          });
        })
        .finally((this.collaborators.loading = false));
    },
    GetAction() {
      if (this.action == "update") {
        this.departmentForm = { ...this.department };
        this.collaborators.selectedCollaborator = this.department?.manager?.id;
      }
    },
    Valid() {
      if (this.departmentForm.name?.length > 0) {
        this.$emit("valid", true);
      } else {
        this.$emit("valid", false);
      }
    },
    Save() {
      switch (this.action) {
        case "create":
          this.CreateDepartment();
          break;
        case "update":
          this.UpdateDepartment();
          break;
      }
    },
    async UpdateDepartment() {
      this.$emit("progress", true);
      setTimeout(async () => {
        await this.DepartmentApi.Update(this.GetPayload(), this.departmentForm.id)
          .then(() => {
            this.$toast.add({
              severity: "success",
              summary: "Sucesso",
              detail: "Operação realizada com sucesso",
              life: 3000,
            });
          })
          .finally(() => {
            this.$emit("progress", false);
            this.$emit("close");
          });
      }, 2000);
    },
    async CreateDepartment() {
      this.$emit("progress", true);
      setTimeout(async () => {
        await this.DepartmentApi.Create(this.GetPayload())
          .then(() => {
            this.$toast.add({
              severity: "success",
              summary: "Sucesso",
              detail: `Operação realizada com sucesso`,
              life: 2000,
            });
          })
          .finally(() => {
            this.$emit("progress", false);
            this.$emit("close");
          });
      }, 2000);
    },
    GetPayload() {
      let payload = {};
      try {
        payload = {
          name: this.departmentForm.name,
          managerId: this.collaborators.selectedCollaborator,
        };
        return payload;
      } catch {
        this.$toast.add({
          severity: "error",
          summary: "Erro critico",
          detail: "Ocorreu um erro ao obter as informações necessárias",
          life: 3000,
        });
      }
    },
  },
  watch: {
    save: function () {
      this.Save();
    },
  },
});
</script>

<template>
  <div class="flex flex-column field">
    <label for="name">Nome:</label>
    <InputText
      id="name"
      v-model="departmentForm.name"
      placeholder="Nome do departamento"
      @input="Valid()"
    />
  </div>
  <div class="flex flex-column field">
    <label for="ceo">Gerente/Supervisor:</label>
    <Dropdown
      id="manager"
      v-model="collaborators.selectedCollaborator"
      optionLabel="label"
      optionValue="value"
      placeholder="Selecione a pessoa"
      emptyFilterMessage="Sem resultados"
      emptyMessage="Sem resultados"
      :filter="true"
      :options="collaborators.records"
      :loading="collaborators.loading"
      @change="Valid()"
    />
  </div>

</template>
