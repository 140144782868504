import AxiosInstance from '@/services/AxiosInstance'

const menusEndpoint = "/menus"

export default class MenuRouter {
    async Search(filterParams) {
        return await AxiosInstance.get(`${menusEndpoint}?search=${filterParams}`)
            .then(resp => resp.data)
            .then(d => d.data)
    }
    async FindAll() {
        return await AxiosInstance.get(menusEndpoint)
            .then(resp => resp.data)
            .then(d => d.data)
    }
    async FindById(id) {
        return await AxiosInstance.get(`${menusEndpoint}/${id}`)
            .then(resp => resp.data)
            .then(d => d.data)
    }
    async Create(payload) {
        return await AxiosInstance.post(menusEndpoint, payload)
            .then(resp => resp.data)
            .then(d => d.data)
    }
    async Update(payload, id) {
        return await AxiosInstance.put(`${menusEndpoint}/${id}`, payload)
            .then(resp => resp.data)
            .then(d => d.data)
    }
    async Remove(payload) {
        return await AxiosInstance.delete(menusEndpoint, { data: payload })
            .then(resp => resp.data)
            .then(d => d.data)
    }
}