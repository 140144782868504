<script>
import { defineComponent } from 'vue';
import Upload from '../Components/Upload.vue';
import StepControls from '../Components/StepControls.vue';
import DocumentAttributes from '../Components/DocumentAttributes';
export default defineComponent({
    props: ['formData'],
    components: {
        Upload,
        StepControls,
        DocumentAttributes
    },
    data() {
        return {
            contentTypeOptions: ['Arquivo', 'Texto'],
            contentType: 'Arquivo',
            disableNextButton: true,
            hideBackButton: false,
            textContent: '',
            lockControls: false,
            showAttributesModal: false,
            interceptNext: true,
            validAttrs: false,
            form: {
                formData: { documentContent: { value: '', content: '' } },
                pageIndex: 1
            }
        };
    },
    methods: {
        onUploadFinished(event) {
            let uploadResponse = JSON.parse(event.xhr.responseText);
            this.lockControls = false;
            this.disableNextButton = false;
            this.$toast.add({
                severity: 'info',
                summary: 'Sucesso',
                detail: 'Arquivo enviado',
                life: 3000
            });
            this.form.formData.documentContent = {
                value: this.contentType == 'Arquivo' ? 'file' : 'text',
                content: this.contentType == 'Arquivo' ? uploadResponse.data.filename : this.textContent
            };
            if (!uploadResponse.data?.supports.split || !uploadResponse.data?.supports.encrypt) {
                this.$confirm.require({
                    message: `Este documento não foi reconhecido como um recibo de pagamento
                    para identificação dos destinatários, deseja continuar mesmo assim?`,
                    header: 'Documento não reconhecido',
                    icon: 'fas fa-exclamation-triangle',
                    acceptLabel: 'Sim',
                    rejectLabel: 'Não',
                    reject: () => {
                        return;
                    }
                });
            }

            this.documentAttributes = {
                split: uploadResponse.data?.supports.split,
                encrypt: uploadResponse.data?.supports.encrypt,
                password: uploadResponse.data?.supports.password
            };
        },
        onUploading() {
            this.lockControls = true;
        },
        onUploadError(event) {
            if (event.xhr.status == 400) {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Arquivo inválido',
                    detail: 'O tipo de arquivo enviado não é aceito',
                    life: 3000
                });
                this.lockControls = false;
            } else {
                let responseErr = JSON.parse(event.xhr.response);
                this.$toast.add({
                    severity: 'error',
                    summary: 'Falha',
                    detail: responseErr.error,
                    life: 3000
                });
            }
        },
        handlerValid(event) {
            this.validAttrs = event;
        },
        closeAttrsModal() {
            if (this.validAttrs) {
                this.showAttributesModal = false;
            } else {
                this.$toast.add({
                    severity: 'warn',
                    summary: 'Atenção',
                    detail: 'Por favor preencha todos os campos obrigatórios',
                    life: 3000
                });
            }
        },

        nextStep(event) {
            this.interceptNext = false;
            this.form.formData.documentContent.properties = event;
            this.$emit('nextPage', this.form);
        },
        prevPage() {
            this.$emit('prevPage', this.form);
        }
    },
    computed: {
        typePreset() {
            return this.formData?.documentType.value == 'payroll' || this.formData?.documentType.value == 'ir';
        },
        hasDocumentType() {
            return this.formData?.documentType?.value;
        },
        fileType() {
            return this.contentType == 'Arquivo';
        }
    }
});
</script>

<template>
    <Dialog v-model:visible="showAttributesModal" :breakpoints="{ '960px': '75vw', '640px': '90vw' }" :style="{ width: '29vw' }" :maximizable="false" :modal="true" :header="'Propriedades do documento'" @hide="showAttributesModal = false" class="p-fluid" :closable="false">
        <DocumentAttributes :documentSupports="documentAttributes" :formData="formData" :contentType="contentType" @valid="handlerValid" @documentProperties="nextStep($event)" />
        <template #footer>
            <Button label="Continuar" iconPos="right" icon="pi pi-angle-right" autofocus @click="closeAttrsModal()" class="w-12rem" />
        </template>
    </Dialog>
    <div>
        <div v-if="hasDocumentType" class="flex flex-column card-container justify-content-start">
            <div class="flex flex-column align-items-left mb-3" v-if="!typePreset">
                <SelectButton v-model="contentType" :options="contentTypeOptions" aria-labelledby="single" @change="disableNextButton = true" />
            </div>
            <div v-if="fileType" class="flex flex-column align-items-left font-bold">
                <Upload :disabled="lockControls" :mime-types="this.formData?.documentType?.mimeTypes" @uploading="onUploading" @upload-complete="onUploadFinished($event)" @upload-error="onUploadError($event)"></Upload>
            </div>
            <div v-if="!fileType">
                <TextArea v-model="textContent" :autoResize="false" rows="5" cols="30" class="h-23rem w-full" @input="disableNextButton = false" />
            </div>
        </div>
    </div>
    <StepControls v-if="hasDocumentType" :form="form" :disableNextButton="disableNextButton" :emitOnly="interceptNext" @nextClicked="showAttributesModal = true" @backClicked="prevPage()" :hideBackButton="hideBackButton" />
</template>
