import AxiosInstance from '@/services/AxiosInstance';

const collaboratorEndpoint = '/collaborators';
const collaboratorPunches = `${collaboratorEndpoint}/punches`
const collaboratorPunchesSync = `${collaboratorEndpoint}/punches/sync`

export default class CollaboratorRouter {
    async Search(filterParams) {
        return await AxiosInstance.get(`${collaboratorEndpoint}?search=${filterParams}`)
            .then((resp) => resp.data)
            .then((d) => d.data);
    }
    async SearchWithPendingPunches(filterParams) {
        return await AxiosInstance.get(`${collaboratorPunches}/pendencies?search=${filterParams}`)
            .then((resp) => resp.data)
            .then((d) => d.data);
    }
    async FindAll() {
        return await AxiosInstance.get(collaboratorEndpoint)
            .then((resp) => resp.data)
            .then((d) => d.data);
    }
    async Create(payload) {
        return await AxiosInstance.post(collaboratorEndpoint, payload)
            .then((resp) => resp.data)
            .then((d) => d.data);
    }
    async Update(payload, id) {
        return await AxiosInstance.put(`${collaboratorEndpoint}/${id}`, payload)
            .then((resp) => resp.data)
            .then((d) => d.data);
    }
    async Remove(payload) {
        return await AxiosInstance.delete(collaboratorEndpoint, { data: payload })
            .then((resp) => resp.data)
            .then((d) => d.data);
    }
    async FindPunchPendencies() {
        return await AxiosInstance.get(`${collaboratorPunches}/user-pendencies`)
            .then((resp) => resp.data)
            .then((d) => d.data);
    }
    async SyncPunches(collaboratorIds = []){
        return await AxiosInstance.post(collaboratorPunchesSync, collaboratorIds)
        .then((resp) => resp.data)
        .then((d) => d.data)
    }
    async CheckSyncStatus(){
        return await AxiosInstance.get(`${collaboratorPunchesSync}/status`)
        .then((resp) => resp.data)
        .then((d) => d.data)
    }
}
