import AxiosInstance  from "@/services/AxiosInstance";

const collaboratorEventTypeEndpoint = "/collaborator-event-types"

export default class CollaboratorEventTypeRouter {
    async FindAll() {
        return await AxiosInstance.get(collaboratorEventTypeEndpoint)
            .then(resp => resp.data)
            .then(d => d.data)
    }
}