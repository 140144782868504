import Upload from './Upload/Upload'
import SendQueue from './SendQueue/SendQueue'
import DocumentTypeStep from './Upload/Steps/DocumentType'
import DocumentContentStep from './Upload/Steps/DocumentContent'
import RecipientStep from './Upload/Steps/DocumentRecipient.vue'
import UserDocuments from './All/Documents'
// import Pending from './Pending/Documents'
import Manager from './Manager/Manager'

const router = [
    {
        path: "/documents",
        name: "new",
        component: Upload,
        children: [
            {
                path: "upload",
                component: DocumentTypeStep
            },
            {
                path: "upload/content",
                component: DocumentContentStep
            },
            {
                path: "upload/recipients",
                component: RecipientStep,
            }
        ]
    },
    {
        path: "send-queue",
        name: "sendQueue",
        component: SendQueue,
    },
    {
        path: "manager",
        name: "manager",
        component: Manager
    },
    {
        path: "user/:event",
        name: "userDocuments",
        component: UserDocuments,
    },
    // {
    //     path: "pendings",
    //     name: "pending",
    //     component: Pending
    // }
];

export default router;