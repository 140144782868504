<script>
import { FilterMatchMode } from "primevue/api";
import { defineComponent } from "vue";
import PersonRouter from "@/api/routers/Person";
import EnvelopeRouter from "@/api/routers/Envelope";
import DocumentRouter from "@/api/routers/Document";
import PDFViewer from "../../Utils/PDFViewer.vue";
import FilterModal from "../../Utils/Filter.vue";
import moment from "moment";

export default defineComponent({
  components: {
    PDFViewer,
    FilterModal,
  },
  data() {
    return {
      loading: false,
      showContentModal: false,
      filterModal: {
        show: false,
        showFilters: ["envelopeStatus", "scheduled", "people", "referenceDate"],
        selectedFilters: {},
      },
      hasFilters: false,
      envelopes: {
        selectedDocuments: [],
        totalRecords: 0,
        records: [],
      },
      autoUpdateLst: [
        { label: "Não atualizar", value: null },
        { label: "5 Segundos", value: 5000 },
        { label: "10 Segundos", value: 10000 },
        { label: "30 Segundos", value: 30000 },
        { label: "1 Minuto", value: 60000 },
      ],
      autoUpdateTime: { label: "Não atualizar", value: null },
      autoUpdateInterval: null,
      pagination: {},
      pageRows: 10,
      filters: { label: { value: "", matchMode: "contains" } },
      filter_event: [
        { label: "Enviados", value: "sent" },
        { label: "Em fila", value: "wait" },
        { label: "Falha no envio", value: "error" },
      ],
      filter_scheduled: [
        { label: "Todos", value: "all" },
        { label: "Agendados", value: "scheduled" },
        { label: "Sem agendamento", value: "not_scheduled" },
      ],
      peopleFilter: [],
      PersonApi: null,
      EnvelopeApi: null,
      DocumentApi: null,
      pdf: {
        rotate: 1,
        zoom: 500,
      },
      slugIdentifier: "",
    };
  },
  beforeUnmount() {
    clearInterval(this.autoUpdateInterval);
  },
  created() {
    this.PersonApi = new PersonRouter();
    this.EnvelopeApi = new EnvelopeRouter();
    this.DocumentApi = new DocumentRouter();
    this.FilterInit();
  },
  async mounted() {
    await this.GetQueueUrlParameters();
    this.pageRows = this.$refs.dt.rows;
    this.OnFilter();
    this.loadPeople();
  },
  methods: {
    async loadPeople() {
      this.peopleFilter = await this.PersonApi.FindAll().then((resp) => {
        return resp.people?.map((p) => {
          return { label: p.name, value: p.id };
        });
      });
    },
    async GetQueueUrlParameters() {
      if (this.$router.currentRoute._value.query.queueId != undefined) {
        this.slugIdentifier = this.$router.currentRoute._value.query.queueId;
      }
      if (this.$router.currentRoute._value.query.updateTime != undefined) {
        let updateTime = this.$router.currentRoute._value.query.updateTime;
        this.autoUpdateTime = this.autoUpdateLst.find(
          (item) => item?.value == updateTime
        );
        this.ReloadUpdateInterval();
      }
    },
    LoadSendQueue() {
      this.loading = true;
      this.envelopes = {
        selectedDocuments: [],
        totalRecords: 0,
        records: [],
      };
      setTimeout(() => {
        this.EnvelopeApi.Search(JSON.stringify(this.pagination))
          .then((resp) => {
            this.envelopes.totalRecords = resp.totalRecords;
            this.envelopes.records = resp.envelopes;
          })
          .finally((this.loading = false));
      }, Math.random() * 1000 + 250);
    },
    ReloadUpdateInterval() {
      if (this.autoUpdateTime.value == null) {
        clearInterval(this.autoUpdateInterval);
        return;
      }
      clearInterval(this.autoUpdateInterval);
      this.autoUpdateInterval = setInterval(() => {
        this.LoadSendQueue();
      }, this.autoUpdateTime.value);
    },
    StatusTitle(event) {
      switch (event) {
        case "sent":
          return "Enviado";
        case "wait":
          return "Em fila";
        case "error":
          return "Falha no envio";
        default:
          return "Criado";
      }
    },
    async OnRemove(slot) {
      this.$confirm.require({
        message:
          "Tem certeza que deseja remove este item da lista de envelopes?",
        header: "Remover entrada",
        icon: "far fa-question-circle",
        acceptLabel: "Sim",
        rejectLabel: "Não",
        accept: () => {
          let payload = [];
          payload.push(slot.id);
          this.EnvelopeApi.Remove(payload).finally(this.LoadSendQueue());
        },
      });
    },
    async OnResend(slot) {
      let payload = [];
      payload.push(slot.id);
      await this.EnvelopeApi.Resend(payload).finally(this.LoadSendQueue());
    },
    async OnViewContent(slot) {
      if (slot.documents[0].documentType.name == "file") {
        let payload = { file: slot?.documents[0].content };
        await this.DocumentApi.View("base64", payload).then(
          (resp) => (this.pdf.base64 = resp?.base64File)
        );
        this.pdf.password = slot?.documents[0].password;
        this.showContentModal = true;
      }
    },
    SetZoom(type) {
      let minZoom = 100;
      let maxZoom = 1200;
      if (type == "IN" && this.pdf.zoom != maxZoom) {
        return (this.pdf.zoom += 100);
      } else if (type == "OUT" && this.pdf.zoom != minZoom) {
        return (this.pdf.zoom -= 100);
      }
    },
    CloseFilterModal() {
      this.filterModal.show = false;
    },
    ShowFilterModal() {
      this.filterModal.show = true;
    },
    MakeFilter(filter) {
      this.filterModal.selectedFilters = Object.assign(
        {},
        this.filterModal.selectedFilters,
        filter
      );
      this.filters.global = Object.assign({}, this.filters.global, filter);
    },
    ClearFilters() {
      this.filterModal.selectedFilters = [];
      this.FilterInit();
      this.hasFilters = false;
      this.OnFilter();
    },
    FilterSearch() {
      this.filterModal.show = false;
      this.OnFilter();
    },
    OnDownload() {
      window.open(this.pdf.base64, "_blank");
    },
    FilterInit() {
      this.pagination = {
        page: 1,
        rows: this.pageRows,
      };
      this.filters = {
        global: {
          envelopeStatus: [],
          scheduled: "all",
          scheduledTo: null,
          people: [],
          referenceDate: null,
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
      };
    },
    OnFilter() {
      if (Object.keys(this.filterModal.selectedFilters).length > 0) {
        this.hasFilters = true;
      }
      if (this.filters.global?.referenceDate != null) {
        this.filters.global.referenceDate = moment(
          this.filters.global.referenceDate
        ).format("MM/YY");
      }
      this.filters.global.value = this.slugIdentifier;
      this.pagination.filters = this.filters;
      this.LoadSendQueue();
    },
    OnPage(event) {
      this.pagination = event;
      this.LoadSendQueue();
    },
    OnSort(event) {
      this.pagination = event;
      this.LoadSendQueue();
    },
    OnClearFilter() {
      this.FilterInit();
      this.pagination = this.filters;
      this.LoadSendQueue();
    },
  },
});
</script>

<template>
  <Card>
    <template #title>
      <span class="font-light text-700 text-xl">Fila de processamento</span>
    </template>
    <template #content>
      <Toolbar>
        <template v-slot:start>
          <div class="flex flex-column justify-content-end">
            <span class="mb-1 text-sm">Atualizar a cada:</span>
            <Dropdown
              id="autoUpdateTime"
              v-model="autoUpdateTime"
              :options="autoUpdateLst"
              optionLabel="label"
              @change="ReloadUpdateInterval()"
              placeholder="Tempo de atualização"
              class="mr-3 w-14rem"
            />
          </div>
        </template>
        <template v-slot:end>
          <div class="lg:mt-0 sm:mt-2 flex flex-row" style="gap: 0.5rem">
            <Button
              icon="fas fa-redo-alt"
              class="p-button-rounded p-button-info p-button-outlined p-button-raised"
              @click="LoadSendQueue()"
              :disabled="loading"
              title="Atualizar lista"
            />
            <div class="relative">
              <Button
                icon="fas fa-filter"
                class="p-button p-button-rounded p-button-info p-button-outlined p-button-raised"
                @click="ShowFilterModal()"
                :disabled="loading"
                title="Escolher filtros"
              />
              <Button
                v-if="hasFilters"
                icon="fas fa-broom"
                class="p-button-rounded p-button-raised clear-filter"
                title="Limpar filtros"
                @click="ClearFilters()"
                :disabled="loading"
              />
            </div>
          </div>
        </template>
      </Toolbar>
    </template>
    <template #footer>
      <DataTable
        ref="dt"
        :value="envelopes.records"
        dataKey="id"
        :paginator="true"
        :rows="10"
        v-model:filters="filters"
        :loading="loading"
        :totalRecords="envelopes.totalRecords"
        @page="OnPage($event)"
        @filter="OnFilter($event)"
        @sort="OnSort($event)"
        lazy="true"
        v-model:selection="selectedDocuments"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 20, 50, 100, 300]"
        currentPageReportTemplate="Exibindo de {first} a {last} de {totalRecords} entradas"
        responsiveLayout="scroll"
        class="p-datatable-sm"
      >
        <template #header />

        <Column
          field="id"
          header="ID"
          :sortable="false"
          headerStyle="width:5%; min-width:2rem;"
        >
          <template #body="slotProps">
            <span class="p-column-title">ID</span>
            <span>{{ slotProps.data.id }}</span>
          </template>
        </Column>
        <Column
          field="registration"
          header="Matrícula"
          :sortable="false"
          headerStyle="width:10%; min-width:2rem;"
        >
          <template #body="slotProps">
            <span class="p-column-title">Matrícula</span>
            <span>{{
              slotProps.data.people[0].collaborator?.employerRegistrationNumber
            }}</span>
          </template>
        </Column>
        <Column
          field="collaborator"
          header="Colaborador"
          :sortable="false"
          headerStyle="width:30%; min-width:10rem;"
        >
          <template #body="slotProps">
            <span class="p-column-title">Colaborador</span>
            <span>{{ slotProps.data?.people[0].name }}</span>
          </template>
        </Column>
        <Column
          field="content"
          header="Conteúdo"
          :sortable="false"
          headerStyle="width:15%; min-width:5rem;"
        >
          <template #body="slotProps">
            <span class="p-column-title">Conteúdo</span>
            <Button
              :icon="
                slotProps.data?.documents[0].documentType.name == 'file'
                  ? 'fas fa-file'
                  : 'fas fa-file-alt'
              "
              class="p-button-rounded p-button-sm p-button-info p-button-outlined ml-3 action-button"
              title="Ver conteúdo"
              @click="OnViewContent(slotProps.data)"
            />
          </template>
        </Column>
        <Column
          field="content"
          header="Status"
          :sortable="false"
          headerStyle="width:23%; min-width:5rem;"
        >
          <template #body="slotProps">
            <span class="p-column-title">Status</span>
            <span class="font-bold text-700">{{
              StatusTitle(slotProps.data?.status)
            }}</span>
          </template>
        </Column>
        <Column headerStyle="min-width:5rem;">
          <template #body="slotProps">
            <Button
              :disabled="
                slotProps.data?.status == 'sent' ||
                slotProps.data?.status == 'wait' ||
                slotProps.data?.status == 'created'
              "
              icon="fas fa-paper-plane"
              class="p-button-rounded p-button-sm p-button-info p-button-outlined mr-2 action-button"
              title="Reenviar"
              @click="OnResend(slotProps.data)"
            />
            <Button
              icon="fas fa-trash"
              :disabled="slotProps.data?.status == 'sent'"
              class="p-button-rounded p-button-sm p-button-danger p-button-outlined mr-2 action-button"
              title="Remover da lista envio"
              @click="OnRemove(slotProps.data)"
            />
          </template>
        </Column>
      </DataTable>
    </template>
  </Card>
  <Dialog
    v-model:visible="showContentModal"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :maximizable="true"
    :modal="true"
    :header="'Pre-visualizar documento'"
    @hide="showContentModal = false"
    class="p-fluid"
  >
    <PDFViewer
      :pdf="pdf.base64"
      :password="pdf.password"
      :zoom="pdf.zoom"
      :rotate="pdf.rotate"
    ></PDFViewer>
    <template #footer>
      <div class="flex mt-4 justify-content-between">
        <div>
          <Button
            icon="fa-solid fa-magnifying-glass-plus ml-2 mr-2"
            @click="SetZoom('IN')"
            title="Aumentar o zoom"
            autofocus
          />
          <Button
            icon="fa-solid fa-magnifying-glass-minus ml-2 mr-2"
            @click="SetZoom('OUT')"
            title="Reduzir o zoom"
            autofocus
          />
          <Button
            icon="fa-solid fa-rotate-right ml-2 mr-2"
            @click="pdf.rotate++"
            title="Girar 90º"
            autofocus
          />
          <Button
            icon="fa-solid fa-download ml-2 mr-2"
            title="Baixar documento"
            @click="OnDownload()"
            autofocus
          />
        </div>
        <div>
          <Button
            label="Ok"
            icon="fa-solid fa-check"
            autofocus
            @click="showContentModal = false"
          />
        </div>
      </div>
    </template>
  </Dialog>
  <Dialog
    v-model:visible="filterModal.show"
    header="Filtro avançado"
    :modal="true"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '30vw' }"
    :maximizable="false"
  >
    <FilterModal
      @filterSelection="MakeFilter($event)"
      :showFilters="filterModal.showFilters"
      :currentFilters="filterModal.selectedFilters"
    />
    <template #footer>
      <div class="flex flex-row justify-content-between">
        <Button
          label="Cancelar"
          iconPos="right"
          icon="pi pi-times"
          autofocus
          @click="CloseFilterModal()"
          class="w-12rem"
        />
        <Button
          label="Pesquisar"
          iconPos="right"
          icon="pi pi-search"
          autofocus
          @click="FilterSearch()"
          class="w-12rem"
        />
      </div>
    </template>
  </Dialog>
</template>
