<script>
import { FilterMatchMode } from "primevue/api";
import { defineComponent } from "vue";
import EnvelopeRouter from "@/api/routers/Envelope";
import DocumentRouter from "@/api/routers/Document";
import PDFViewer from "../../Utils/PDFViewer.vue";
import FilterModal from "../../Utils/Filter.vue";
import EnvelopeEventsTypesRouter from "@/api/routers/EnvelopeEventTypes";
import LastEnvelopeEventModal from "./LastEnvelopeEventModal.vue";
import EnvelopeEventsRouter from "@/api/routers/EnvelopeEvents";
import EnvelopeTimeline from "../../Utils/EnvelopeTimeline.vue";
import moment from "moment";
export default defineComponent({
  components: {
    PDFViewer,
    FilterModal,
    LastEnvelopeEventModal,
    EnvelopeTimeline,
  },
  data() {
    return {
      loading: false,
      showContentModal: false,
      filterModal: {
        show: false,
        showFilters: ["people", "envelopeEventsTypes", "companies", "referenceDate"],
        selectedFilters: {},
      },
      hasFilters: false,
      envelopes: {
        selectedEnvelopes: [],
        totalRecords: 0,
        records: [],
      },
      lastEnvelopeEventModal: {
        envelope: {},
        show: false,
      },
      envelopeTimeline: {
        envelope: null,
        show: false,
      },
      envelopeEventTypes: [],
      pagination: {},
      pageRows: 10,
      filters: { label: { value: "", matchMode: "contains" } },
      peopleFilter: [],
      EnvelopeApi: null,
      DocumentApi: null,
      EnvelopeEventTypesApi: null,
      EnvelopeEventsApi: null,
      pdf: {
        rotate: 1,
        zoom: 500,
      },
      slugIdentifier: "",
    };
  },
  created() {
    this.EnvelopeApi = new EnvelopeRouter();
    this.DocumentApi = new DocumentRouter();
    this.EnvelopeEventsApi = new EnvelopeEventsRouter();
    this.EnvelopeEventTypesApi = new EnvelopeEventsTypesRouter();
    this.FilterInit();
  },
  async mounted() {
    await this.LoadAllEnvelopeEventsTypes();
    this.pageRows = this.$refs.dt.rows;
    this.OnFilter();
  },
  methods: {
    LoadEnvelopesList() {
      this.loading = true;
      this.envelopes = {
        selectedEnvelopes: [],
        totalRecords: 0,
        records: [],
      };
      setTimeout(() => {
        this.EnvelopeApi.Search(JSON.stringify(this.pagination))
          .then((resp) => {
            this.envelopes.totalRecords = resp.totalRecords;
            this.envelopes.records = resp.envelopes;
          })
          .finally((this.loading = false));
      }, Math.random() * 1000 + 250);
    },
    ReloadUpdateInterval() {
      if (this.autoUpdateTime.value == null) {
        clearInterval(this.autoUpdateInterval);
        return;
      }
      clearInterval(this.autoUpdateInterval);
      this.autoUpdateInterval = setInterval(() => {
        this.LoadEnvelopesList();
      }, this.autoUpdateTime.value);
    },
    ShowEnvelopeTimeline(slot) {
      this.envelopeTimeline.show = true;
      this.envelopeTimeline.envelope = { ...slot };
    },
    ResetTimelineModalState() {
      this.envelopeTimeline.envelope = null;
    },
    OnResend() {
      this.$confirm.require({
        header: "Confirmar reenvio",
        message: "Tem certeza que deseja reenviar os envelopes selecionados?",
        acceptLabel: "Sim",
        rejectLabel: "Não",
        acceptClass: "p-button-success",
        accept: async () => {
          await this.EnvelopeApi.Resend(
            this.envelopes.selectedEnvelopes.map((e) => {
              return e.id;
            })
          ).finally(this.LoadEnvelopesList());
        },
      });
    },
    async OnViewContent(slot) {
      if (slot.documents[0].documentType.name == "file") {
        let payload = { file: slot?.documents[0].content };
        await this.DocumentApi.View("base64", payload).then(
          (resp) => (this.pdf.base64 = resp?.base64File)
        );
        this.pdf.password = slot?.documents[0].password;
        this.showContentModal = true;
      }
    },
    SetZoom(type) {
      let minZoom = 100;
      let maxZoom = 1200;
      if (type == "IN" && this.pdf.zoom != maxZoom) {
        return (this.pdf.zoom += 100);
      } else if (type == "OUT" && this.pdf.zoom != minZoom) {
        return (this.pdf.zoom -= 100);
      }
    },
    CloseFilterModal() {
      this.filterModal.show = false;
    },
    ShowFilterModal() {
      this.filterModal.show = true;
    },
    MakeFilter(filter) {
      this.filterModal.selectedFilters = Object.assign(
        {},
        this.filterModal.selectedFilters,
        filter
      );
      this.filters.global = Object.assign({}, this.filters.global, filter);
    },
    ClearFilters() {
      this.filterModal.selectedFilters = [];
      this.FilterInit();
      this.hasFilters = false;
      this.OnFilter();
    },
    FilterSearch() {
      this.filterModal.show = false;
      this.OnFilter();
    },
    OnDownload() {
      window.open(this.pdf.base64, "_blank");
    },
    FilterInit() {
      this.pagination = {
        page: 1,
        rows: this.pageRows,
      };
      this.filters = {
        global: {
          envelopeStatus: [],
          scheduled: "all",
          scheduledTo: null,
          people: [],
          referenceDate: null,
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
      };
    },
    LastEvent(slot) {
      try {
        slot.sort(function (a, b) {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        return this.envelopeEventTypes.find(
          (eet) => eet.id === slot[0].envelopeEventTypeID
        ).description;
      } catch (e) {
        console.log(e);
        return "";
      }
    },
    CancelledEvent(slot) {
      try {
        slot.sort(function (a, b) {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        return (
          this.envelopeEventTypes.find(
            (eet) => eet.id === slot[0].envelopeEventTypeID
          ).slug === "cancelled"
        );
      } catch (e) {
        console.log(e);
        return "";
      }
    },
    LastEnvelopeEventModal(slot) {
      try {
        slot?.envelopeEvents.sort(function (a, b) {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        this.lastEnvelopeEventModal.envelope = { ...slot };
        this.lastEnvelopeEventModal.show = true;
      } catch (e) {
        console.log(e);
        return "";
      }
    },
    async LoadAllEnvelopeEventsTypes() {
      this.envelopeEventTypes = await this.EnvelopeEventTypesApi.FindAll().then(
        (data) => {
          return data.envelopeEventTypes;
        }
      );
    },
    ResetLastEventModalDetails() {
      this.lastEnvelopeEventModal.show = false;
      this.lastEnvelopeEventModal.envelope = {};
    },
    async OnCancelEnvelope(slot) {
      this.$confirm.require({
        header: "Cancelar envelope",
        message:
          "Ao efetuar o cancelamento do envelope o mesmo se tornará inutilizável, prosseguir?",
        acceptLabel: "Sim",
        rejectLabel: "Não",
        acceptClass: "p-button-danger",
        accept: async () => {
          let eventTypeId = await this.EnvelopeEventTypesApi.FindBySlug(
            "cancelled"
          ).then((data) => {
            return data.envelopeEventType.id;
          });
          let payload = {
            envelopeId: slot.id,
            envelopeEventTypeId: eventTypeId,
          };
          this.EnvelopeEventsApi.Create(payload)
            .then(() => {
              this.$toast.add({
                severity: "success",
                summary: "Sucesso",
                detail: "Operação realizada com sucesso",
                life: 3000,
              });
            })
            .finally(() => {
              this.LoadEnvelopesList();
            });
        },
      });
    },
    OnFilter() {
      if (Object.keys(this.filterModal.selectedFilters).length > 0) {
        this.hasFilters = true;
      }
      if (this.filters.global?.referenceDate != null) {
        this.filters.global.referenceDate = moment(
          this.filters.global.referenceDate
        ).format("MM/YY");
      }
      this.filters.global.value = this.slugIdentifier;
      this.pagination.filters = this.filters;
      this.LoadEnvelopesList();
    },
    OnPage(event) {
      this.pagination = event;
      this.LoadEnvelopesList();
    },
    OnSort(event) {
      this.pagination = event;
      this.LoadEnvelopesList();
    },
    OnClearFilter() {
      this.FilterInit();
      this.pagination = this.filters;
      this.LoadEnvelopesList();
    },
  },
});
</script>

<template>
  <Card>
    <template #title>
      <span class="font-light text-700 text-xl">Envelopes</span>
    </template>
    <template #content>
      <Toolbar>
        <template v-slot:start>
          <div class="lg:mt-0 sm:mt-2 flex flex-row" style="gap: 0.5rem">
            <Button
              icon="fas fa-share"
              class="p-button-rounded p-button-info p-button-outlined p-button-raised"
              @click="OnResend()"
              :disabled="loading || envelopes.selectedEnvelopes?.length == 0"
              title="Reenviar envelopes selecionados"
            />
          </div>
        </template>
        <template v-slot:end>
          <div class="lg:mt-0 sm:mt-2 flex flex-row" style="gap: 0.5rem">
            <Button
              icon="fas fa-redo-alt"
              class="p-button-rounded p-button-info p-button-outlined p-button-raised"
              @click="LoadEnvelopesList()"
              :disabled="loading"
              title="Atualizar lista"
            />
            <div class="relative">
              <Button
                icon="fas fa-filter"
                class="p-button p-button-rounded p-button-info p-button-outlined p-button-raised"
                @click="ShowFilterModal()"
                :disabled="loading"
                title="Escolher filtros"
              />
              <Button
                v-if="hasFilters"
                icon="fas fa-broom"
                class="p-button-rounded p-button-raised clear-filter"
                title="Limpar filtros"
                @click="ClearFilters()"
                :disabled="loading"
              />
            </div>
          </div>
        </template>
      </Toolbar>
    </template>
    <template #footer>
      <DataTable
        ref="dt"
        :value="envelopes.records"
        dataKey="id"
        :paginator="true"
        :rows="10"
        v-model:filters="filters"
        :loading="loading"
        :totalRecords="envelopes.totalRecords"
        @page="OnPage($event)"
        @filter="OnFilter($event)"
        @sort="OnSort($event)"
        lazy="true"
        v-model:selection="envelopes.selectedEnvelopes"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 20, 50, 100, 300]"
        currentPageReportTemplate="Exibindo de {first} a {last} de {totalRecords} entradas"
        responsiveLayout="scroll"
        class="p-datatable-sm"
      >
        <template #header />
        <Column
          selectionMode="multiple"
          headerStyle="width:.2%; min-width:1rem;"
        />
        <Column
          field="id"
          header="ID"
          :sortable="false"
          headerStyle="width:5%; min-width:2rem;"
        >
          <template #body="slotProps">
            <span class="p-column-title">ID</span>
            <span>{{ slotProps.data.id }}</span>
          </template>
        </Column>
        <Column
          field="registration"
          header="Matrícula"
          :sortable="false"
          headerStyle="width:10%; min-width:2rem;"
        >
          <template #body="slotProps">
            <span class="p-column-title">Matrícula</span>
            <span>{{
              slotProps.data.people[0].collaborator?.employerRegistrationNumber
            }}</span>
          </template>
        </Column>
        <Column
          field="collaborator"
          header="Colaborador"
          :sortable="false"
          headerStyle="width:30%; min-width:10rem;"
        >
          <template #body="slotProps">
            <span class="p-column-title">Colaborador</span>
            <span>{{ slotProps.data?.people[0].name }}</span>
          </template>
        </Column>
        <Column
          field="content"
          header="Conteúdo"
          :sortable="false"
          headerStyle="width:15%; min-width:5rem;"
        >
          <template #body="slotProps">
            <span class="p-column-title">Conteúdo</span>
            <Button
              :icon="
                slotProps.data?.documents[0].documentType.name == 'file'
                  ? 'fas fa-file'
                  : 'fas fa-file-alt'
              "
              class="p-button-rounded p-button-sm p-button-info p-button-outlined ml-3 action-button"
              title="Ver conteúdo"
              @click="OnViewContent(slotProps.data)"
            />
          </template>
        </Column>
        <Column
          field="lastEvent"
          header="Último evento"
          headerStyle="width:23%; min-width:5rem;"
          :sortable="false"
        >
          <template #body="slotProps">
            <span class="p-column-title">Último evento</span>
            <Button
              class="p-button-text p-button-rounded"
              @click="LastEnvelopeEventModal(slotProps.data)"
              title="Clique para ver mais detalhes"
            >
              <span>{{ LastEvent(slotProps.data.envelopeEvents) }}</span>
            </Button>
          </template>
        </Column>
        <Column headerStyle="min-width:5rem;">
          <template #body="slotProps">
            <Button
              icon="fas fa-ban"
              class="p-button-rounded p-button-sm p-button-danger p-button-outlined mr-2 action-button"
              title="Cancelar envelope"
              :disabled="CancelledEvent(slotProps.data.envelopeEvents)"
              @click="OnCancelEnvelope(slotProps.data)"
            />
            <Button
              icon="fas fa-stream"
              class="p-button-rounded p-button-sm p-button-warning p-button-outlined mr-2 action-button"
              title="Eventos do envelope"
              @click="ShowEnvelopeTimeline(slotProps.data)"
            />
          </template>
        </Column>
      </DataTable>
    </template>
</Card>
    <Dialog
    v-model:visible="showContentModal"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :maximizable="true"
    :modal="true"
    :header="'Pre-visualizar documento'"
    @hide="showContentModal = false"
    class="p-fluid"
  >
    <PDFViewer
      :pdf="pdf.base64"
      :password="pdf.password"
      :zoom="pdf.zoom"
      :rotate="pdf.rotate"
    ></PDFViewer>
    <template #footer>
      <div class="flex mt-4 justify-content-between">
        <div>
          <Button
            icon="fa-solid fa-magnifying-glass-plus ml-2 mr-2"
            @click="SetZoom('IN')"
            title="Aumentar o zoom"
            autofocus
          />
          <Button
            icon="fa-solid fa-magnifying-glass-minus ml-2 mr-2"
            @click="SetZoom('OUT')"
            title="Reduzir o zoom"
            autofocus
          />
          <Button
            icon="fa-solid fa-rotate-right ml-2 mr-2"
            @click="pdf.rotate++"
            title="Girar 90º"
            autofocus
          />
          <Button
            icon="fa-solid fa-download ml-2 mr-2"
            title="Baixar documento"
            @click="OnDownload()"
            autofocus
          />
        </div>
        <div>
          <Button
            label="Ok"
            icon="fa-solid fa-check"
            autofocus
            @click="showContentModal = false"
          />
        </div>
      </div>
    </template>
  </Dialog>
  <Dialog
    v-model:visible="lastEnvelopeEventModal.show"
    header="Detalhes do evento"
    :modal="true"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '30vw' }"
    :maximizable="true"
  >
    <LastEnvelopeEventModal
      :selectedEnvelope="lastEnvelopeEventModal.envelope"
      :envelopeEventTypes="envelopeEventTypes"
    />
    <template #footer>
      <div class="flex flex-row justify-content-end">
        <Button
          label="Fechar"
          iconPos="right"
          icon="pi pi-times"
          autofocus
          @click="ResetLastEventModalDetails()"
          class="w-12rem"
        />
      </div>
    </template>
  </Dialog>
  <Dialog
    v-model:visible="filterModal.show"
    header="Filtro avançado"
    :modal="true"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '30vw' }"
    :maximizable="false"
  >
    <FilterModal
      @filterSelection="MakeFilter($event)"
      :showFilters="filterModal.showFilters"
      :currentFilters="filterModal.selectedFilters"
    />
    <template #footer>
      <div class="flex flex-row justify-content-between">
        <Button
          label="Cancelar"
          iconPos="right"
          icon="pi pi-times"
          autofocus
          @click="CloseFilterModal()"
          class="w-12rem"
        />
        <Button
          label="Pesquisar"
          iconPos="right"
          icon="pi pi-search"
          autofocus
          @click="FilterSearch()"
          class="w-12rem"
        />
      </div>
    </template>
  </Dialog>
  <Dialog
    v-model:visible="envelopeTimeline.show"
    header="Eventos do envelope"
    :modal="true"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    style="width: 30vw"
    :maximizable="false"
    @hide="ResetTimelineModalState()"
  >
    <EnvelopeTimeline
      :envelope="envelopeTimeline.envelope"
      :envelopeEventsTypesList="envelopeEventTypes"
    />
    <template #footer>
      <div class="flex flex-row justify-content-end">
        <Button
          label="Ok"
          iconPos="right"
          autofocus
          @click="envelopeTimeline.show = false"
          class="w-auto"
        />
      </div>
    </template>
  </Dialog>
</template>
