import AxiosInstance from '@/services/AxiosInstance'

const envelopeTypesEndpoint = '/envelope-types'


export default class EnvelopeTypesRouter {
    async FindAll() {
        return await AxiosInstance.get(envelopeTypesEndpoint)
            .then(resp => resp.data)
            .then(d => d.data)        
    }
    
}