import AxiosInstance from '@/services/AxiosInstance'

const dashboardEndpoint = '/dashboard'
const widgetsEndpoint = `${dashboardEndpoint}/widgets`

export default class DashboardRouter {
    async EnvelopesCount(){
        return await AxiosInstance.get(`${widgetsEndpoint}/envelopes-count`)
            .then((resp) => resp.data)
    }
    async PunchesInfo() {
        return await AxiosInstance.get(`${widgetsEndpoint}/punches/info`)
            .then((resp) => resp.data)
    }
}