<script>
import { defineComponent } from 'vue';

export default defineComponent({
    props: ['formData'],

});
</script>

<template>
    <div></div>
</template>