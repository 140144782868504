import AxiosInstance from "@/services/AxiosInstance";

const roleEndpoint = '/roles'

export default class RoleRouter {
    async Search(filterParams) {
        return await AxiosInstance.get(`${roleEndpoint}?search=${filterParams}`)
            .then(resp => resp.data)
            .then(d => d.data)
    }
    async FindAll() {
        return await AxiosInstance.get(roleEndpoint)
            .then(resp => resp.data)
            .then(d => d.data)
    }
    async Create(payload) {
        return await AxiosInstance.post(payload)
            .then(resp => resp.data)
            .then(d => d.data)
    }
    async Update(payload, id) {
        return await AxiosInstance.put(`${roleEndpoint}/${id}`, payload)
            .then(resp => resp.data)
            .then(d => d.data)
    }
    async Remove(payload) {
        return await AxiosInstance.delete(roleEndpoint, { data: payload })
            .then(resp => resp.data)
            .then(d => d.data)
    }
}