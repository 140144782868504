<script>
import { defineComponent } from 'vue';
import PersonRouter from '@/api/routers/Person';
import CompanyRouter from '@/api/routers/Company';
import EnvelopeTypesRouter from '@/api/routers/EnvelopeTypes';
import DepartmentRouter from '@/api/routers/Department';
import MenuRouter from '@/api/routers/Menu';
import EnvelopeEventsTypesRouter from '@/api/routers/EnvelopeEventTypes';
import moment from 'moment';
export default defineComponent({
    emits: ['filterSelection'],
    props: ['showFilters', 'currentFilters'],
    data() {
        return {
            referenceFilter: {
                selectedReferenceDate: null
            },
            envelopeTypeFilter: {
                loading: false,
                selectedEnvelopeTypes: [],
                envelopeTypes: []
            },
            createdAtFilter: {
                selectedCreatedAt: []
            },
            punchFilter: {
                dateRange: [],
                status: ''
            },
            peopleFilter: {
                people: [],
                selectedPeople: [],
                loading: false
            },
            companiesFilter: {
                companies: [],
                selectedCompanies: [],
                loading: false
            },
            menusFilter: {
                menus: [],
                selectedMenus: [],
                loading: false
            },
            departmentsFilter: {
                departments: [],
                selectedDepartments: [],
                loading: false
            },
            envelopeEventsTypesFilter: {
                events: [],
                selectedEvents: [],
                loading: false
            },
            generalFilter: null,
            punchFilterStatus: {
                status: [
                    { label: 'Todos', value: 'all' },
                    { label: 'Apenas com pendências', value: 'pending' }
                ],
                selectedStatus: null,
            },
            envelopeStatusFilter: {
                status: [
                    { label: 'Criado', value: 'created' },
                    { label: 'Enviado', value: 'sent' },
                    { label: 'Em fila', value: 'wait' },
                    { label: 'Falha no envio', value: 'error' }
                ],
                selectedStatus: []
            },
            scheduledFilter: {
                status: [
                    { label: 'Todos', value: 'all' },
                    { label: 'Somente agendados', value: 'scheduled' },
                    { label: 'Somente sem agendamento', value: 'not_scheduled' }
                ],
                scheduledTo: null,
                selectedSchedule: {}
            },
            userStatusFilter: {
                status: [
                    { label: 'Todos', value: 'all' },
                    { label: 'Ativo apenas', value: 'enabled' },
                    { label: 'Inativo apenas', value: 'disabled' }
                ],
                selectedStatus: {}
            },
            PersonApi: null,
            CompanyApi: null,
            EnvelopeApi: null,
            DepartmentApi: null,
            MenuRouter: null,
            EnvelopeEventsTypesApi: null
        };
    },
    created() {
        this.PersonApi = new PersonRouter();
        this.CompanyApi = new CompanyRouter();
        this.EnvelopeTypesApi = new EnvelopeTypesRouter();
        this.DepartmentApi = new DepartmentRouter();
        this.MenuApi = new MenuRouter();
        this.EnvelopeEventsTypesApi = new EnvelopeEventsTypesRouter();
    },
    mounted() {
        this.GetShowFilters();
        this.GetCurrentFilters();
    },
    methods: {
        async LoadEnvelopeEventsTypes() {
            this.envelopeEventsTypesFilter.loading = true;
            this.envelopeEventsTypesFilter.events = await this.EnvelopeEventsTypesApi.FindAll()
                .then((resp) => {
                    return resp.envelopeEventTypes.map((eet) => {
                        return { label: eet.name, value: eet.id };
                    });
                })
                .finally((this.envelopeEventsTypesFilter.loading = false));
        },
        async LoadDepartments() {
            this.departmentsFilter.loading = true;
            this.departmentsFilter.departments = await this.DepartmentApi.FindAll()
                .then((resp) => {
                    return resp.departments.map((d) => {
                        return { label: d.name, value: d.id };
                    });
                })
                .finally((this.departmentsFilter.loading = false));
        },
        async LoadPeople() {
            this.peopleFilter.loading = true;
            this.peopleFilter.people = await this.PersonApi.FindAll()
                .then((resp) => {
                    return resp.people?.map((p) => {
                        return { label: p.name, value: p.id };
                    });
                })
                .finally((this.peopleFilter.loading = false));
        },
        async LoadEnvelopeTypes() {
            this.envelopeTypeFilter.loading = true;
            this.envelopeTypeFilter.envelopeTypes = await this.EnvelopeTypesApi.FindAll()
                .then((resp) => {
                    return resp.envelopeTypes.map((et) => {
                        return { label: et.name, value: et.id };
                    });
                })
                .finally((this.envelopeTypeFilter.loading = false));
        },
        async LoadCompanies() {
            this.companiesFilter.loading = true;
            this.companiesFilter.companies = await this.CompanyApi.FindAll()
                .then((resp) => {
                    return resp.companies?.map((c) => {
                        return { label: c.name, value: c.id };
                    });
                })
                .finally((this.companiesFilter.loading = false));
        },
        async LoadMenus() {
            this.menusFilter.loading = true;
            this.menusFilter.menus = await this.MenuApi.FindAll()
                .then((resp) => {
                    return resp.menus?.map((m) => {
                        return { label: m.label, value: m.id };
                    });
                })
                .finally((this.menusFilter.loading = false));
        },
        GetCurrentFilters() {
            const { referenceDate, people, companies, envelopeStatus, envelopeEvents, envelopeEventsTypes, scheduled, scheduledTo, userStatus, departments, menus, value, createdAt, envelopeTypes, dateRange } = this.currentFilters || {};

            this.referenceFilter.selectedReferenceDate = referenceDate;
            this.peopleFilter.selectedPeople = people;
            this.companiesFilter.selectedCompanies = companies;
            this.envelopeStatusFilter.selectedStatus = envelopeStatus;
            this.envelopeEventsFilter = envelopeEvents;
            this.envelopeEventsTypesFilter.selectedEvents = envelopeEventsTypes;
            this.scheduledFilter.selectedSchedule = scheduled;
            this.scheduledFilter.scheduledTo = scheduledTo;
            this.userStatusFilter.selectedStatus = userStatus;
            this.departmentsFilter.selectedDepartments = departments;
            this.menusFilter.selectedMenus = menus;
            this.generalFilter = value;
            this.createdAtFilter.selectedCreatedAt = createdAt;
            this.envelopeTypeFilter.selectedEnvelopeTypes = envelopeTypes;
            this.punchFilter.dateRange = this.FormatDateRange(dateRange, 'DD/MM/YYYY')
        },
        EmitFilter(filter) {
            this.$emit('filterSelection', filter);
        },
        GetShowFilters() {
            this.showFilters.forEach((filterName) => {
                switch (filterName) {
                    case 'people':
                        this.LoadPeople();
                        break;
                    case 'companies':
                        this.LoadCompanies();
                        break;
                    case 'departments':
                        this.LoadDepartments();
                        break;
                    case 'menus':
                        this.LoadMenus();
                        break;
                    case 'envelopeEventsTypes':
                        this.LoadEnvelopeEventsTypes();
                        break;
                    case 'envelopeTypes':
                        this.LoadEnvelopeTypes();
                        break;
                }
            });
        },
        FormatReferenceDate(date) {
            return moment(date).format('YYYY-MM-DD');
        },
        FormatDateRange(dateArr, layout = 'YYYY-MM-DD') {
            return dateArr?.map((date) => moment(date).format(layout));
        },
        ShowFilter(filtername) {
            return this.showFilters.find((item) => item === filtername) != undefined;
        }
    }
});
</script>

<template>
    <div class="flex flex-column field" v-if="ShowFilter('general')">
        <label for="general">Localizar:</label>
        <InputText id="general" placeholder="Buscar..." v-model="generalFilter" @input="EmitFilter({ value: generalFilter })" />
    </div>
    <div class="flex flex-column field" v-if="ShowFilter('people')">
        <label for="people">Pessoa:</label>
        <MultiSelect
            id="people"
            v-model="peopleFilter.selectedPeople"
            :options="peopleFilter.people"
            optionLabel="label"
            optionValue="value"
            placeholder="Selecionar pessoa(s)"
            emptyMessage="Sem resultados"
            emptyFilterMessage="Sem resultados"
            :filter="true"
            :loading="this.peopleFilter.loading"
            @change="EmitFilter({ people: peopleFilter.selectedPeople })"
        />
    </div>
    <div class="flex flex-column field" v-if="ShowFilter('companies')">
        <label for="companies">Empresa:</label>
        <MultiSelect
            id="companies"
            v-model="companiesFilter.selectedCompanies"
            :options="companiesFilter.companies"
            optionLabel="label"
            optionValue="value"
            placeholder="Selecionar companhia(s)"
            emptyMessage="Sem resultados"
            emptyFilterMessage="Sem resultados"
            :filter="true"
            :loading="this.companiesFilter.loading"
            @change="EmitFilter({ companies: companiesFilter.selectedCompanies })"
        />
    </div>
    <div class="flex flex-column field" v-if="ShowFilter('menus')">
        <label for="menus">Menu/Pai:</label>
        <MultiSelect
            id="menus"
            v-model="menusFilter.selectedMenus"
            :options="menusFilter.menus"
            optionLabel="label"
            optionValue="value"
            placeholder="Selecionar menus pai"
            emptyMessage="Sem resultados"
            emptyFilterMessage="Sem resultados"
            :filter="true"
            :loading="this.menusFilter.loading"
            @change="EmitFilter({ menus: menusFilter.selectedMenus })"
        />
    </div>
    <div class="flex flex-column field" v-if="ShowFilter('userStatus')">
        <label for="userStatus">Ativo/Inativo:</label>
        <Dropdown
            id="userStatus"
            v-model="userStatusFilter.selectedStatus"
            optionLabel="label"
            optionValue="value"
            placeholder="Selecione um status do usuário"
            emptyMessage="Sem resultados"
            emptyFilterMessage="Sem resultados"
            :options="userStatusFilter.status"
            @change="EmitFilter({ userStatus: userStatusFilter.selectedStatus })"
        />
    </div>
    <div class="flex flex-column field" v-if="ShowFilter('envelopeStatus')">
        <label for="envelopeStatus">Status do envelope:</label>
        <MultiSelect
            id="envelopeStatus"
            v-model="envelopeStatusFilter.selectedStatus"
            optionLabel="label"
            optionValue="value"
            placeholder="Selecionar status do envelope"
            emptyMessage="Sem resultados"
            emptyFilterMessage="Sem resultados"
            :options="envelopeStatusFilter.status"
            :filter="true"
            @change="EmitFilter({ envelopeStatus: envelopeStatusFilter.selectedStatus })"
        />
    </div>
    <div class="flex flex-column field" v-if="ShowFilter('envelopeEventsTypes')">
        <label for="envelopeEvents">Eventos do envelope:</label>
        <MultiSelect
            id="envelopeEvents"
            v-model="envelopeEventsTypesFilter.selectedEvents"
            optionLabel="label"
            optionValue="value"
            placeholder="Selecionar eventos do envelope"
            emptyMessage="Sem resultados"
            emptyFilterMessage="Sem resultados"
            :options="envelopeEventsTypesFilter.events"
            :filter="true"
            :loading="envelopeEventsTypesFilter.loading"
            @change="
                EmitFilter({
                    envelopeEventsTypes: envelopeEventsTypesFilter.selectedEvents
                })
            "
        />
    </div>
    <div class="flex flex-column field" v-if="ShowFilter('departments')">
        <label for="departments">Departamentos:</label>
        <MultiSelect
            id="departments"
            v-model="departmentsFilter.selectedDepartments"
            optionLabel="label"
            optionValue="value"
            placeholder="Selecionar um ou mais departamentos"
            emptyMessage="Sem resultados"
            emptyFilterMessage="Sem resultados"
            :options="departmentsFilter.departments"
            :filter="true"
            @change="EmitFilter({ departments: departmentsFilter.selectedDepartments })"
        />
    </div>
    <div class="flex flex-column field" v-if="ShowFilter('scheduled')">
        <label for="scheduled">Agendamento:</label>
        <Dropdown
            id="scheduled"
            v-model="scheduledFilter.selectedSchedule"
            optionLabel="label"
            optionValue="value"
            placeholder="Selecione um status de agendamento"
            emptyMessage="Sem resultados"
            emptyFilterMessage="Sem resultados"
            :options="scheduledFilter.status"
            @change="EmitFilter({ scheduled: scheduledFilter.selectedSchedule })"
        />
    </div>
    <div class="flex flex-column field" v-if="scheduledFilter.selectedSchedule == 'scheduled'">
        <label for="scheduled">Data/Hora agendamento:</label>
        <Calendar
            style="height: 39px"
            :showButtonBar="true"
            id="scheduledTo"
            inputId="range"
            :showTime="false"
            v-model="scheduledFilter.scheduledTo"
            selectionMode="range"
            :manualInput="false"
            :showIcon="true"
            @date-select="EmitFilter({ scheduledTo: scheduledFilter.scheduledTo })"
        />
    </div>
    <div class="flex flex-column field" v-if="ShowFilter('referenceDate')">
        <label for="companies">Data de referência:</label>
        <Calendar
            style="height: 39px"
            :showButtonBar="true"
            id="reference"
            inputId="single"
            view="month"
            dateFormat="mm/yy"
            v-model="referenceFilter.selectedReferenceDate"
            selectionMode="single"
            :manualInput="false"
            :showIcon="true"
            @date-select="
                EmitFilter({
                    referenceDate: FormatReferenceDate(referenceFilter.selectedReferenceDate)
                })
            "
        />
    </div>
    <div class="flex flex-column field" v-if="ShowFilter('envelopeTypes')">
        <label for="envelopeType">Tipo de documento:</label>
        <MultiSelect
            id="envelopeType"
            v-model="envelopeTypeFilter.selectedEnvelopeTypes"
            :options="envelopeTypeFilter.envelopeTypes"
            optionLabel="label"
            optionValue="value"
            placeholder="Selecione um tipo de documento"
            emptyMessage="Sem resultados"
            emptyFilterMessage="Sem resultados"
            :filter="true"
            :loading="this.envelopeTypeFilter.loading"
            @change="EmitFilter({ envelopeTypes: envelopeTypeFilter.selectedEnvelopeTypes })"
        />
    </div>
    <div class="flex flex-column field" v-if="ShowFilter('createdAt')">
        <label for="createdAt">Criado em:</label>
        <Calendar
            style="height: 39px"
            :showButtonBar="true"
            id="createdAt"
            inputId="single"
            dateFormat="dd/mm/yy"
            view="date"
            v-model="createdAtFilter.selectedCreatedAt"
            selectionMode="range"
            :manualInput="false"
            :showIcon="true"
            @date-select="
                EmitFilter({
                    createdAt: FormatDateRange(createdAtFilter.selectedCreatedAt)
                })
            "
        />
    </div>
    <div class="flex flex-column field" v-if="ShowFilter('punchDateRange')">
        <label for="punchDateRange">Pendências entre:</label>
        <Calendar
            style="height: 39px"
            :showButtonBar="true"
            id="punchDateRange"
            inputId="single"
            dateFormat="dd/mm/yy"
            view="date"
            v-model="punchFilter.dateRange"
            selectionMode="range"
            :manualInput="false"
            :showIcon="true"
            @date-select="
                EmitFilter({
                    dateRange: FormatDateRange(punchFilter.dateRange)
                })
            "
        />
    </div>
</template>
