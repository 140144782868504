import AxiosInstance from '@/services/AxiosInstance'

const accountEndpoint = "/account"

export default class AccountRouter {
    async Create(payload) {
        return await AxiosInstance.post(accountEndpoint, payload)
            .then(resp => resp.data)
            .then(d => d.data)
    }
    async Update(payload) {
        return await AxiosInstance.put(accountEndpoint, payload)
            .then(resp => resp.data)
            .then(d => d.data)
    }
    async FirebaseRegistrationToken(payload) {
        return await AxiosInstance.post(`${accountEndpoint}/firebase-token`, payload)
            .then(resp => resp.data)
            .then(d => d.data)
    }
    async TelegramNotification(accepted) {
        return await AxiosInstance.get(`${accountEndpoint}/telegram-notification?accepted=${accepted}`)
    }
    async ChangePassword(payload){
        return await AxiosInstance.post(`${accountEndpoint}/change-password`, payload)
    }
    
}