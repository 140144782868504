import AxiosInstance from '@/services/AxiosInstance';

const payrollDocumentModel = '/payroll-document-model'

export default class PayrollDocumentModelRouter {
    async FindAll() {
        return await AxiosInstance.get(payrollDocumentModel)
        .then((resp) => resp.data)
        .then((d) => d.data)
    }
}