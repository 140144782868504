<script>
import AppTopBar from './components/AppTopbar.vue';
import AppMenu from './components/AppMenu.vue';
import AppFooter from './components/AppFooter.vue';
import EventBus from './common/EventBus';
import AppEventBus from './layout/composables/AppEventBus';
import AuthRouter from './api/routers/Auth';
import { ThemeAppearance, UserInfo } from './services/StorageKeys';
import LoadApplicationData from './services/ApplicationData';
import AccountRouter from './api/routers/Account';
import ServerRouter from './api/routers/Server';
import firebase from 'firebase/app';
import 'firebase/messaging';
import Lottie from '@/components/Utils/Lottie.vue';
import Notification from '@/layout/lotties/notification.json';


export default {
    emits: ['change-theme', 'view-profile'],
    data() {
        return {
            layoutMode: 'static',
            isLoading: false,
            staticMenuInactive: false,
            overlayMenuActive: false,
            mobileMenuActive: false,
            AuthApi: null,
            loggedInUsername: null,
            person: {},
            userDetails: {},
            menu: [],
            userInfoResponse: null,
            telegramNotification: false,
            lottie: {
                animation: Notification,
                height: 250,
                width: 350,
                speed: 0.6,
                loop: true
            },
            AccountApi: null,
            ServerApi: null
        };
    },
    created() {
        this.FirebaseRegistration();
        this.onChangeTheme(true);
        this.AuthApi = new AuthRouter();
        this.AccountApi = new AccountRouter();
        this.ServerApi = new ServerRouter();
    },
    mounted() {
        this.$emitter.on('error', this.emitterError);
        EventBus.on('logout', () => {
            this.logout();
        });
        this.ServerStatus();
        setTimeout(() => {
            this.loadUserInfo();
        }, 500);
    },
    unmounted() {
        this.$emitter.off('error', this.emitterError);
    },
    beforeUnmount() {
        EventBus.remove('logout');
    },
    watch: {
        $router() {
            this.menuActive = false;
            this.$toast.removeAllGroups();
        }
    },
    methods: {
        async ServerStatus() {
            let serverMaintenance = await this.ServerApi.Status();
            if (serverMaintenance.maintenance_mode) {
                this.$router.push('/pages/maintenance');
            }
        },
        FirebaseRegistration() {
            try {
                const firebaseConfig = {
                    apiKey: 'AIzaSyCWvClgdmFtFzb5iIk7LzKikGBNJ7bM1yA',
                    authDomain: 'fibralink-e396b.firebaseapp.com',
                    databaseURL: 'https://fibralink-e396b.firebaseio.com',
                    projectId: 'fibralink-e396b',
                    storageBucket: 'fibralink-e396b.appspot.com',
                    messagingSenderId: '894646192318',
                    appId: '1:894646192318:web:ff69b957990bd647f8b6ef',
                    measurementId: 'G-1BMHEKNG4Y'
                };

                firebase.initializeApp(firebaseConfig);
                firebase
                    .messaging()
                    .requestPermission()
                    .then(() => {
                        return firebase
                            .messaging()
                            .getToken()
                            .then((token) => {
                                this.SendTokenRegistration(token);
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    });
            } catch (err) {
                console.log(err);
            }
        },

        SendTokenRegistration(token) {
            let payload = {
                token: token,
                client: 'khambalia_web'
            };
            this.AccountApi.FirebaseRegistrationToken(payload).catch((err) => {
                console.log(err);
            });
        },
        emitterError(event) {
            this.$toast.add(event);
        },
        onWrapperClick() {
            if (!this.menuClick) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            }

            this.menuClick = false;
        },
        onMenuToggle() {
            this.menuClick = true;

            if (this.isDesktop()) {
                if (this.layoutMode === 'overlay') {
                    if (this.mobileMenuActive === true) {
                        this.overlayMenuActive = true;
                    }

                    this.overlayMenuActive = !this.overlayMenuActive;
                    this.mobileMenuActive = false;
                } else if (this.layoutMode === 'static') {
                    this.staticMenuInactive = !this.staticMenuInactive;
                }
            } else {
                this.mobileMenuActive = !this.mobileMenuActive;
            }

            event.preventDefault();
        },
        onSidebarClick() {
            this.menuClick = true;
        },
        onMenuItemClick(event) {
            if (event.item && !event.item.items) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            }
        },
        onLayoutChange(layoutMode) {
            this.layoutMode = layoutMode;
        },
        addClass(element, className) {
            if (element.classList) element.classList.add(className);
            else element.className += ' ' + className;
        },
        removeClass(element, className) {
            if (element.classList) element.classList.remove(className);
            else element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        },
        isDesktop() {
            return window.innerWidth >= 992;
        },
        isSidebarVisible() {
            if (this.isDesktop()) {
                if (this.layoutMode === 'static') return !this.staticMenuInactive;
                else if (this.layoutMode === 'overlay') return this.overlayMenuActive;
            }

            return true;
        },
        onLogout() {
            this.$store.dispatch('auth/logout');
            this.$router.push('/auth/login');
        },
        onChangeTheme(setOnly) {
            let currentTheme = localStorage.getItem(ThemeAppearance);
            if (!currentTheme) {
                localStorage.setItem(ThemeAppearance, 'light');
                currentTheme = 'light';
            }
            if (setOnly) {
                let schema = `lara-${currentTheme}-blue`;
                this.$appState.colorScheme = currentTheme;
                AppEventBus.emit('theme-change', {
                    theme: schema,
                    dark: currentTheme === 'dark'
                });
                return;
            }
            let newTheme = 'dark';
            switch (currentTheme) {
                case 'dark':
                    newTheme = 'light';
                    break;
                case 'light':
                    newTheme = 'dark';
                    break;
            }
            this.$appState.colorScheme = newTheme;
            let newSchema = `lara-${newTheme}-blue`;
            AppEventBus.emit('theme-change', {
                theme: newSchema,
                dark: newTheme === 'dark'
            });
            localStorage.setItem(ThemeAppearance, newTheme);
        },
        digestMenus(menu) {
            let menuItems = {
                label: menu.label,
                icon: menu.icon
            };
            switch (menu.menuTypeId) {
                case 1:
                    menuItems.items = menu.items?.map((m) => this.digestMenus(m));
                    break;
                case 2:
                    menuItems.to = menu.action;
                    break;
                case 3:
                    menuItems.url = menu.action;
                    break;
                case 4:
                    menuItems.command = () => {
                        eval(menu.action);
                    };
                    break;
                default:
                    menuItems.to = '/';
            }
            return menuItems;
        },
        async loadUserInfo() {
            await LoadApplicationData()
                .then((data) => {
                    this.menu = data.userInfo.menus.map(this.digestMenus);
                    this.loggedInUsername = data.loggedInUsername;
                    this.person = data.userInfo?.person;
                    this.userInfoResponse = data;
                })
                .finally(() => {
                    this.setUserProfile(this.userInfoResponse.userInfo);
                });
        },
        setUserProfile(usr) {
            this.userDetails = {
                ID: usr.ID,
                name: usr.name,
                username: usr.username,
                extAuth: !usr?.ldap_auth ? false : true
            };

            this.userInfoResponse.userInfo.telegramNotification == true || this.userInfoResponse.userInfo.telegramNotification == false ? (this.telegramNotification = false) : (this.telegramNotification = true);
        },
        CloseModal(result) {
            this.AccountApi.TelegramNotification(result);
            this.telegramNotification = false;
            localStorage.removeItem(UserInfo);
            setTimeout(() => {
                location.reload();
            }, 500);
        },
        RegisterTelegramNotification() {
            window.open('https://t.me/gpFibralinkBot');
            this.CloseModal(true);
        }
    },
    computed: {
        containerClass() {
            return [
                'layout-wrapper',
                {
                    'layout-overlay': this.layoutMode === 'overlay',
                    'layout-static': this.layoutMode === 'static',
                    'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
                    'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
                    'layout-mobile-sidebar-active': this.mobileMenuActive,
                    'p-input-filled': this.$primevue.config.inputStyle === 'filled',
                    'p-ripple-disabled': this.$primevue.config.ripple === false
                }
            ];
        },
        logo() {
            return 'images/logo.svg';
        }
    },
    beforeUpdate() {
        if (this.mobileMenuActive) this.addClass(document.body, 'body-overflow-hidden');
        else this.removeClass(document.body, 'body-overflow-hidden');
    },
    components: {
        AppTopBar: AppTopBar,
        AppMenu: AppMenu,
        AppFooter: AppFooter,
        Lottie
    }
};
</script>

<template>
    <div :class="containerClass" @click="onWrapperClick">
        <AppTopBar @menu-toggle="onMenuToggle" @change-theme="onChangeTheme(false)" @user-logout="onLogout" @view-profile="onViewProfile" :loggedInUsername="loggedInUsername" :person="person" />
        <div class="layout-sidebar" @click="onSidebarClick">
            <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
        </div>

        <div class="layout-main-container">
            <div class="layout-main">
                <router-view />
            </div>
            <AppFooter />
        </div>

        <transition name="layout-mask">
            <div class="layout-mask p-component-overlay" v-if="mobileMenuActive"></div>
        </transition>
    </div>

    <Dialog v-model:visible="telegramNotification" header="Notificações via Telegram" :modal="true" :breakpoints="{ '960px': '75vw', '640px': '90vw' }" :style="{ width: '35vw' }" :maximizable="false">
        <div class="flex flex-column">
            <div class="flex flex-row mt-4">
                <Lottie :lottieProp="this.lottie" />
                <div class="flex w-10 text-justify">
                    <span class="font-medium text-lg"
                        >Ao ativar as notificações via telegram, você receberá alertas em tempo real sempre que houver alguma notificação disponível para você. Para habilitar clique no botão "Inscrever-se", caso não queira habilitar as notificações via telegram e não mostrar esta notificação, clique
                        no botão "Não, obrigado"</span
                    >
                </div>
            </div>
        </div>

        <template #footer>
            <div class="flex flex-row justify-content-end">
                <Button label="Não, obrigado" iconPos="right" icon="pi pi-times" autofocus @click="CloseModal(false)" class="w-12rem" />
                <Button label="Inscrever-se" iconPos="right" icon="pi pi-arrow-right" autofocus @click="RegisterTelegramNotification()" class="w-12rem" />
            </div>
        </template>
    </Dialog>

    <Loading v-model:active="isLoading" :can-cancel="true" :is-full-page="true" :color="'#85C1E9'" :width="'50'" :height="'50'" :background-color="'#000000'" :opacity="'0.7'" />
    <Toast position="bottom-right" group="error" />
    <Toast position="bottom-right" />
</template>

<style lang="scss">
@import './assets/styles/variables';

html,
body,
span,
label {
    font-family: 'Open Sans', 'Roboto', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
</style>
