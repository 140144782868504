import AxiosInstance from "@/services/AxiosInstance";

const envelopeEventTypesEndpoint = '/envelope-event-types'

export default class EnvelopeEventTypesRouter {
    async FindAll() {
        return await AxiosInstance.get(envelopeEventTypesEndpoint)
            .then(resp => resp.data)
            .then(d => d.data)
    }
    async FindById(id) {
        return await AxiosInstance.get(`${envelopeEventTypesEndpoint}/find?id=${id}`)
            .then(resp => resp.data)
            .then(d => d.data)
    }
    async FindBySlug(slug) {
        return await AxiosInstance.get(`${envelopeEventTypesEndpoint}/find?slug=${slug}`)
            .then(resp => resp.data)
            .then(d => d.data)
    }
}