import Auth from "../services/Auth";
import Token from "../services/Token";

const user = Token.getAuthorizationToken();
const initialState = user
    ? { status: { loggedIn: true }, user }
    : { status: { loggedIn: false }, user: null }

export const auth = {
    namespaced: true,
    state: initialState,
    actions: {
        login({ commit }, user) {
            return Auth.login(user)
                .then(
                    user => {
                        commit('loginSuccess', user);
                        return Promise.resolve(user);
                    },
                    error => {
                        commit('loginFailure');
                        return Promise.reject(error);
                    }
                );
        },
        logout({ commit }) {
            Auth.logout();
            commit('logout');
        },
        refresh({ commit }, refreshToken) {
            commit('refreshToken', refreshToken);
        }
    },
    mutations: {
        loginSuccess(state, user) {
            state.status.loggedIn = true;
            state.user = user;
        },
        loginFailure(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
        logout(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
        refreshToken(state, refreshToken) {
            state.status.loggedIn = true;
            state.user = { ...state.user, refreshToken: refreshToken };
        }
    }
};