import Token from './Token'

export default function Guard() {
    try {
        let token = Token.getAuthorizationToken();
        if (token) {
            const jwt = Token.jwtExpiration(token);
            if (jwt.exp < Date.now() / 1000) {
                this.$store.dispatch("auth/logout")
                return false
            } else {
                return true;
            }
        } else {
            return false
        }
    }
    catch {
        return false
    }
}
