import AxiosInstance from '@/services/AxiosInstance'

const sendChannelsEndpoint = '/send-channels'

export default class SendChannelRouter {
    async FindAll() {
        return await AxiosInstance.get(sendChannelsEndpoint)
            .then(resp => resp.data)
            .then(d => d.data)
    }
}