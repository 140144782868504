import AxiosInstance from '@/services/AxiosInstance'

const menuTypesEndpoint = '/menu-types'

export default class MenuTypesRouter {
    async FindAll() {
        return await AxiosInstance.get(menuTypesEndpoint)
            .then(resp => resp.data)
            .then(d => d.data)
    }
}