import Employee from './Employee'
import Pendencies from './Punches/Pendencies'


const router = [
    {
        path: "/employee",
        name: "employee",
        component: Employee,
        children: [
            {
                path: "punch-pendencies",
                component: Pendencies,
            }
        ]
    }
]

export default router;