<script>
import Lottie from '../Utils/Lottie'
import Maintenance  from './../../layout/lotties/maintenance.json';
export default {
    components: {
        Lottie,
    },
    data() {
        return {
            lottie: {
                animation: Maintenance,
                height: 200,
                width: 350,
                speed: 0.6,
                loop: true
            },
        }
    }
}

</script>

<template>
   <div class="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden pg-background">
        <div class="flex flex-column align-items-center justify-content-center">
            <div class="col-12">
                <div class="surface-card py-6 px-8 card-effect">
                    <div class="grid flex flex-column align-items-center">
                        <span class="font-bold text-gray-900 text-4xl mb-2">Serviço em manutenção</span>
                        <span class="text-gray-500 text-sm mb-5">Estamos trabalhando para melhorar sua experiencia na plataforma</span>
                        <Lottie :lottieProp="this.lottie"/>
                        <div class="col-12 mt-5 text-center">
                            <span class="font-bold text-cyan-500">Volte novamente mais tarde</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
