<script>
import { Vue3Lottie } from 'vue3-lottie';
import 'vue3-lottie/dist/style.css'
export default {
    props: ['lottieProp'],
    components: {
        Vue3Lottie,
    },
    data() {
        return {
            lottie: this.lottieProp,
        }
    },

}
</script>
<template>
    <Vue3Lottie 
    :animationData="lottie.animation" 
    :height="lottie?.height" 
    :width="lottie?.width"
    :speed="lottie?.speed"
    :backgroundColor="lottie?.backgroundColor"
    :loop="lottie?.loop"
    :direction="lottie?.direction">
    </Vue3Lottie>
</template>
