<script>
import { defineComponent } from "vue";
export default defineComponent({
  emits: ["observations"],
  data() {
    return {
      inputObservations: null,
    };
  },
  methods: {
    EmitInput() {
      this.$emit("observations", this.inputObservations)
    }
  }
});
</script>

<template>
  <div class="flex flex-column field">
    <label for="observations">Observações (Não obrigatório):</label>
    <Textarea
      id="observations"
      v-model="inputObservations"
      :autoResize="false"
      rows="10"
      cols="50"
      placeholder="Caso você tenha alguma duvida ou deseja informar algum problema com o documento, caso contrario é so continuar"
      @input="EmitInput()"
    />
  </div>
</template>
