import AxiosInstance from "@/services/AxiosInstance";

const userEndpoint = '/users'

export default class UserRouter {
    async Search(filterParams) {
        return await AxiosInstance.get(`${userEndpoint}?search=${filterParams}`)
            .then(resp => resp.data)
            .then(d => d.data)
    }
    async Create(payload) {
        return await AxiosInstance.post(userEndpoint, payload)
            .then(resp => resp.data)
    }
    async Update(payload, id) {
        return await AxiosInstance.put(`${userEndpoint}/${id}`, payload)
            .then(resp => resp.data)
    }
    async Remove(payload) {
        return await AxiosInstance.delete(userEndpoint, { data: payload })
            .then(resp => resp.data)
    }
    async Switch(id) {
        return await AxiosInstance.post(`${userEndpoint}/switch/${id}`)
            .then(resp => resp.data)
    }
    async GenerateNewPassword(id) {
        return await AxiosInstance.post(`${userEndpoint}/generate-new-password/${id}`)
          .then(resp => resp.data)
          .then(d => d.data)
    }
}