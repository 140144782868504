<script>
import { defineComponent } from 'vue';
import CollaboratorRouter from '@/api/routers/Collaborator';
import DepartmentRouter from '@/api/routers/Department';
import CompanyRouter from '@/api/routers/Company';
import PersonRouter from '@/api/routers/Person';
import CollaboratorEventTypeRouter from '@/api/routers/CollaboratorEventTypes';
import CollaboratorCalendarRouter from '@/api/routers/CollaboratorCalendar';
import CollaboratorWorkScheduleRouter from '@/api/routers/CollaboratorWorkSchedule'

export default defineComponent({
    props: ['action', 'collaborator', 'save'],
    emits: ['valid', 'close', 'progress'],
    data() {
        return {
            collaboratorForm: {
                employerRegistrationNumber: null
            },
            isInVacation: false,
            skipExternalSync: false,
            companies: {
                records: [],
                selectedCompany: null,
                loading: false
            },
            collaboratorCalendars: {
                records: [],
                selectedCollaboratorCalendars: null,
                loading: false
            },
            collaboratorWorkSchedule: {
                records: [],
                selectedCollaboratorWorkSchedule: null,
                loading: false
            },
            collaboratorEventTypes: {
                records: [],
                selectedEventType: null,
                loading: false
            },
            people: {
                records: [],
                selectedPerson: null,
                loading: false
            },
            departments: {
                records: [],
                selectedDepartments: [],
                loading: false
            },
            CollaboratorApi: null,
            CollaboratorEventTypeApi: null,
            CollaboratorCalendarApi: null,
            CollaboratorWorkSchedule: null,
            DepartmentApi: null,
            CompanyApi: null,
            PersonApi: null
        };
    },
    created() {
        this.CollaboratorApi = new CollaboratorRouter();
        this.CollaboratorEventTypeApi = new CollaboratorEventTypeRouter();
        this.CollaboratorCalendarApi = new CollaboratorCalendarRouter();
        this.CollaboratorWorkScheduleApi = new CollaboratorWorkScheduleRouter();
        this.DepartmentApi = new DepartmentRouter();
        this.CompanyApi = new CompanyRouter();
        this.PersonApi = new PersonRouter();
    },
    async mounted() {
        await this.LoadDepartments();
        await this.LoadCompanies();
        await this.LoadPeople();
        await this.LoadCollaboratorCalendars();
        await this.LoadCollaboratorWorkSchedules();
        this.GetAction();
    },
    methods: {
        async GetAction() {
            if (this.action == 'update') {
                await this.LoadCollaboratorEventTypes();
                this.collaboratorForm = { ...this.collaborator };
                this.companies.selectedCompany = this.collaborator?.companyId;
                this.people.selectedPerson = this.collaborator?.personId;
                this.collaboratorWorkSchedule.selectedCollaboratorWorkSchedule = this.collaborator.collaboratorWorkSchedule?.id,
                this.collaboratorEventTypes.selectedEventType = this.CollaboratorStatus(this.collaborator.events);
                this.collaboratorCalendars.selectedCollaboratorCalendars = this.collaborator.collaboratorCalendar?.map((c) => c.id)
                this.departments.selectedDepartments = this.collaborator?.departments?.map((d) => d.id);
                this.isInVacation = this.collaborator.isInVacation;
                this.skipExternalSync = this.collaborator.skipExternalSync;
            }
        },
        async LoadCollaboratorCalendars() {
            this.collaboratorCalendars.loading = true;

            try {
                const { collaboratorCalendars } = await this.CollaboratorCalendarApi.FindAll();
                this.collaboratorCalendars.records = collaboratorCalendars;
            } catch (e) {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Erro',
                    detail: 'Falha ao carregar lista de calendários do colaborador',
                    life: 3000
                });
            } finally {
                this.collaboratorCalendars.loading = false;
            }
        },
        async LoadCollaboratorWorkSchedules() {
           this.collaboratorWorkSchedule.loading = true;
           try {
                const { collaboratorWorkSchedules } = await this.CollaboratorWorkScheduleApi.FindAll();
                this.collaboratorWorkSchedule.records = collaboratorWorkSchedules;
            } catch (e){
                 this.$toast.add({
                    severity: 'error',
                    summary: 'Erro',
                    detail: 'Falha ao carregar lista de escalas de trabalho do colaborador',
                    life: 3000
                });
            } finally{
           this.collaboratorWorkSchedule.loading = false;
            }
        },
        async LoadCollaboratorEventTypes() {
            this.collaboratorEventTypes.loading = true;
            this.collaboratorEventTypes.records = await this.CollaboratorEventTypeApi.FindAll()
                .then((resp) => {
                    return resp.collaboratorEventTypes;
                })
                .finally((this.collaboratorEventTypes.loading = false));
        },
        Valid() {
            if (this.collaboratorForm.employerRegistrationNumber?.length > 0 && this.companies.selectedCompany != null && this.people.selectedPerson != null && this.departments.selectedDepartments.length > 0) {
                this.$emit('valid', true);
            } else {
                this.$emit('valid', false);
            }
        },
        Save() {
            switch (this.action) {
                case 'create':
                    this.CreateCollaborator();
                    break;
                case 'update':
                    this.UpdateCollaborator();
                    break;
            }
        },
        CollaboratorStatus(eventsSlot) {
            try {
                eventsSlot.sort(function (a, b) {
                    return new Date(b.createdAt) - new Date(a.createdAt);
                });
                return eventsSlot[0].collaboratorEvent.id;
            } catch (e) {
                console.log(e);
                return '';
            }
        },
        async UpdateCollaborator() {
            this.$emit('progress', true);
            setTimeout(async () => {
                await this.CollaboratorApi.Update(this.GetPayload(), this.collaboratorForm.id)
                    .then(() => {
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Sucesso',
                            detail: 'Operação realizada com sucesso',
                            life: 3000
                        });
                    })
                    .finally(() => {
                        this.$emit('progress', false);
                        this.$emit('close');
                    });
            }, 2000);
        },
        async CreateCollaborator() {
            this.$emit('progress', true);
            setTimeout(async () => {
                await this.CollaboratorApi.Create(this.GetPayload())
                    .then(() => {
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Sucesso',
                            detail: `Operação realizada com sucesso`,
                            life: 2000
                        });
                    })
                    .finally(() => {
                        this.$emit('progress', false);
                        this.$emit('close');
                    });
            }, 2000);
        },
        async LoadCompanies() {
            this.companies.loading = true;
            this.companies.records = await this.CompanyApi.FindAll()
                .then((resp) => {
                    return resp?.companies.map((c) => {
                        return {
                            label: c.name,
                            value: c.id
                        };
                    });
                })
                .finally((this.companies.loading = false));
        },
        async LoadPeople() {
            this.people.loading = true;
            this.people.records = await this.PersonApi.FindAll()
                .then((resp) => {
                    return resp?.people.map((p) => {
                        return {
                            label: p.name,
                            value: p.id
                        };
                    });
                })
                .finally((this.people.loading = false));
        },
        async LoadDepartments() {
            this.departments.loading = true;
            this.departments.records = await this.DepartmentApi.FindAll()
                .then((resp) => {
                    return resp?.departments.map((d) => {
                        return {
                            label: d.name,
                            value: d.id
                        };
                    });
                })
                .finally((this.departments.loading = false));
        },
        GetPayload() {
            let payload = {};
            try {
                payload = {
                    employerRegistrationNumber: this.collaboratorForm.employerRegistrationNumber,
                    personId: this.people.selectedPerson,
                    companyId: this.companies.selectedCompany,
                    collaboratorWorkScheduleId: this.collaboratorWorkSchedule.selectedCollaboratorWorkSchedule,
                    departmentsIds: this.departments.selectedDepartments,
                    collaboratorCalendarsIds: this.collaboratorCalendars.selectedCollaboratorCalendars,
                    isInVacation: this.isInVacation,
                    skipExternalSync: this.skipExternalSync
                };

                if (this.action == 'update') {
                    payload.collaboratorEventTypeId = this.collaboratorEventTypes.selectedEventType;
                }
                return payload;
            } catch {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Erro critico',
                    detail: 'Ocorreu um erro ao obter as informações necessárias',
                    life: 3000
                });
            }
        }
    },
    watch: {
        save: function () {
            this.Save();
        }
    }
});
</script>

<template>
    <div class="flex flex-column field">
        <label for="employerRegistrationNumber">Matricula:</label>
        <InputText id="employerRegistrationNumber" v-model="collaboratorForm.employerRegistrationNumber" placeholder="Número de registro do colaborador" @input="Valid()" />
    </div>
    <div class="flex flex-column field">
        <label for="person">Pessoa:</label>
        <Dropdown
            id="person"
            v-model="people.selectedPerson"
            optionLabel="label"
            optionValue="value"
            placeholder="Selecione a pessoa"
            emptyFilterMessage="Sem resultados"
            emptyMessage="Sem resultados"
            :filter="true"
            :options="people.records"
            :loading="people.loading"
            @change="Valid()"
        />
    </div>
    <div class="flex flex-column field">
        <label for="company">Empresa:</label>
        <Dropdown
            id="company"
            v-model="companies.selectedCompany"
            optionLabel="label"
            optionValue="value"
            placeholder="Selecione a empresa"
            emptyFilterMessage="Sem resultados"
            emptyMessage="Sem resultados"
            :filter="true"
            :options="companies.records"
            :loading="companies.loading"
            @change="Valid()"
        />
    </div>
    <div class="flex flex-column field">
        <label for="department">Departamentos:</label>
        <MultiSelect
            id="department"
            v-model="departments.selectedDepartments"
            optionLabel="label"
            optionValue="value"
            placeholder="Selecione o departamento"
            emptyFilterMessage="Sem resultados"
            emptyMessage="Sem resultados"
            :filter="true"
            :options="departments.records"
            :loading="departments.loading"
            @change="Valid()"
        />
    </div>
    <div class="flex flex-column field">
        <label for="collaborator_calendars">Calendários:</label>
        <MultiSelect
            id="collaborator_calendars"
            v-model="collaboratorCalendars.selectedCollaboratorCalendars"
            optionLabel="name"
            optionValue="id"
            placeholder="Selecione um ou mais calendários"
            emptyFilterMessage="Sem resultados"
            emptyMessage="Sem resultados"
            :filter="true"
            :options="collaboratorCalendars.records"
            :loading="collaboratorCalendars.loading"
            @change="Valid()"
        />
    </div>
    <div class="flex flex-column field">
        <label for="collaborator_work_schedule">Escala de trabalho:</label>
        <Dropdown
            id="collaborator_work_schedule"
            v-model="collaboratorWorkSchedule.selectedCollaboratorWorkSchedule"
            optionLabel="name"
            optionValue="id"
            placeholder="Selecione a escala de trabalho"
            emptyFilterMessage="Sem resultados"
            emptyMessage="Sem resultados"
            :filter="true"
            :options="collaboratorWorkSchedule.records"
            :loading="collaboratorWorkSchedule.loading"
            display="chip"
            @change="Valid()"
        />
    </div>
    <div class="flex flex-column field" v-if="action == 'update'">
        <label for="status">Status:</label>
        <Dropdown
            id="status"
            v-model="collaboratorEventTypes.selectedEventType"
            optionLabel="description"
            optionValue="id"
            placeholder="Selecione o status do empregado"
            emptyFilterMessage="Sem resultados"
            emptyMessage="Sem resultados"
            :filter="true"
            :options="collaboratorEventTypes.records"
            :loading="collaboratorEventTypes.loading"
            display="chip"
            @change="Valid()"
        />
    </div>
    <div class="flex flex-row field" v-if="action == 'update'">
        <div class="flex" style="gap: 5px" title="Ao marcar esta opção o colaborador não receberá notificações">
            <Checkbox id="is_in_vacation" v-model="this.isInVacation" :binary="true" @change="Valid()"/>
            <label for="is_in_vacation" style="margin-top: 2px">Colaborador de férias</label>
        </div>
    </div>
    <div class="flex flex-row field" v-if="action == 'update'">
        <div class="flex" style="gap: 5px" title="Ao marcar esta opção como verdadeira as sincronizações externas não terão efeito sobre este colaborador">
            <Checkbox id="skip_external_sync" v-model="this.skipExternalSync" :binary="true" @change="Valid()"/>
            <label for="skip_external_sync" style="margin-top: 2px">Não sincronizar informações com serviços externos</label>
        </div>
    </div>
</template>
