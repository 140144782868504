import Users from './User/Users.vue'
import People from './Person/People.vue'
import Collaborators from './Collaborator/Collaborators.vue'
import Companies from './Company/Companies'
import Departments from './Department/Departments'
import CollaboratorPunchPendency from './CollaboratorPunchPendency/Pendencies'
import Roles from './Role/Roles'
import Permissions from './Permission/Permissions'
import Menus from './Menu/Menus'
const router = [
    {
        path: "/admin",
        name: "administration",
        children: [
            {
                path: 'users',
                component: Users
            },
            {
                path: 'people',
                component: People
            },
            {
                path: 'collaborators',
                component: Collaborators,
            },
            {
                path: 'collaborator-punch-pendencies',
                component: CollaboratorPunchPendency,
            },
            {
                path: 'companies',
                component: Companies
            },
            {
                path: 'departments',
                component: Departments
            },
            {
                path: 'roles',
                component: Roles
            },
            {
                path: 'permissions',
                component: Permissions
            },
            {
                path: 'menus',
                component: Menus
            }
        ]

    }
];

export default router;