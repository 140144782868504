import AxiosInstance from '@/services/AxiosInstance';

const quickValidation = '/quick-validation';

export default class QuickValidationRouter {
    async PendingEnvelopes() {
        return await AxiosInstance.get(`${quickValidation}/pending-envelopes`)
            .then((resp) => resp.data)
            .then((d) => d.data);
    }

    async DocumentView(documentId) {
        return await AxiosInstance.get(`${quickValidation}/document-view/${documentId}`)
            .then((resp) => resp.data)
            .then((d) => d.data);
    }

    async AddEnvelopeEvent(payload) {
        return await AxiosInstance.post(`${quickValidation}/add-envelope-event`, payload).
        then((resp) => resp?.data)
    }
}
