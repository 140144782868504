import AxiosInstance from "@/services/AxiosInstance"

const collaboratorCalendarEndpoint = '/collaborator-calendars'

export default class CollaboratorCalendarRouter {
    async FindAll() {
        return await AxiosInstance.get(collaboratorCalendarEndpoint)
            .then(resp => resp.data)
            .then(d => d.data)
    }
}