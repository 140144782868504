 <script>
    import { defineComponent } from 'vue'
    export default defineComponent({
        name: "Colaborador",
        data() {
            return {
                pageTile: this.name
            }
        }
    })
</script>
<template>
    <router-view/>
</template>
