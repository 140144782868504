<script>
import { defineComponent } from "vue";
export default defineComponent({
  props: ["wrongPassword"],
  emits: ["password", "valid"],
  data() {
    return {
      password: null,
    };
  },
  methods: {
    Valid() {
      this.$emit("valid", this.password?.length > 0);
      this.$emit("password", this.password);
    },
  },
});
</script>

<template>
  <div class="flex flex-column field w-full">
    <label for="passwordInput">Informe a senha do documento:</label>
    <Password
      id="passwordInput"
      inputClass="w-22rem mt-2 text-xl"
      :feedback="false"
      v-model="password"
      autocomplete="off"
      @input="Valid()"
      :class="{ 'p-invalid': wrongPassword }"
    />
    <div class="flex flex-row mt-2" style="gap: 0.3rem" v-if="wrongPassword">
        <i class="fas fa-times-circle fa-xl text-red-600" />
      <label class="p-invalid" >Senha inválida</label >
    </div>
  </div>
</template>
