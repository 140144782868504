<script>
import { defineComponent } from 'vue';
import LoadApplicationData from '@/services/ApplicationData';
import TOTPRouter from '@/api/routers/TOTP';
import Token from '@/services/Token';
import AppEventBus from '@/layout/composables/AppEventBus';
import { AccessType } from '../../services/StorageKeys';

export default defineComponent({
    name: 'Login',
    inject: ['AxiosInstance'],

    data() {
        return {
            loading: false,
            action: {
                values: ['login', 'quickValidation'],
                current: null
            },
            login: {
                username: '',
                password: ''
            },
            TOTPApi: null
        };
    },
    created() {
        this.TOTPApi = new TOTPRouter();
        if (this.loggedIn) {
            this.$router.push('/');
        }
    },
    mounted() {
        this.action.current = this.action.values[0];
        this.$emitter.on('error', this.EmitterError);
        this.ClearLocalStorage();
        this.SetLoginTheme();
    },
    unmounted() {
        this.$emitter.off('error', this.EmitterError);
    },
    computed: {
        logoUrl() {
            return `images/logo.svg`;
        },
        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        }
    },
    methods: {
        async LoginSuccess() {
            try {
                this.$router.push(
                    await LoadApplicationData().then((data) => {
                        return data.userInfo.role.indexPage.action;
                    })
                );

                localStorage.setItem(AccessType, 'DEFAULT_ACCESS');
            } catch {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Falha',
                    detail: 'Não foi possível localizar o ponto de entrada inicial para o acesso, por favor contate o suporte',
                    life: 5000
                });
            }
        },
        SetLoginTheme() {
            AppEventBus.emit('theme-change', {
                theme: 'lara-light-blue',
                dark: false
            });
        },
        EmitterError(event) {
            this.$toast.add(event);
        },
        ForgotPassword() {
            this.$router.push('/auth/password/forgot');
        },
        ClearLocalStorage() {
            Token.clearLocalStorage();
        },
        async TOTPAuthentication() {
            const payload = {
                txId: this.login.txID,
                code: this.login.totpCode
            };

            const resp = await this.TOTPApi.Authenticate(payload);
            try {
                const jwtToken = resp.login.accessToken;
                Token.setAuthorizationToken(jwtToken);
                localStorage.setItem(AccessType, 'QUICK_VALIDATION');
                this.$router.push({ name: 'quick-validation' });
            } catch (err) {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Falha',
                    detail: 'Erro desconhecido ao logar usando a chave de verificação',
                    life: 5000
                });
            }
        },
        LoginHandler() {
            this.loading = true;
            if (this.login.username?.length == 0 || this.login.password?.length == 0) {
                this.$toast.add({ severity: 'warn', summary: 'Atenção', detail: 'Verifique se todos os campos foram preenchidos corretamente', life: 3000 });
                this.loading = false;
                return;
            }
            setTimeout(() => {
                this.$store
                    .dispatch('auth/login', this.login)
                    .then(() => {
                        this.LoginSuccess();
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }, 2000);
        }
    }
});
</script>

<template>
    <div class="no-scroll-container">
        <div class="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden pg-background px-3">
            <div class="flex flex-column card py-6 px-4 shadow-3 justify-content-between responsive">
                <div class="surface-card flex flex-column px-2 justify-content-between">
                    <div class="flex flex-column justify-content-between mb-2">
                        <div class="text-left">
                            <img :src="logoUrl" alt="logo" height="40" />
                        </div>
                        <label class="font-medium text-bluegray-700 text-sm mt-1">Gestão de pessoas | Login</label>
                    </div>
                    <Divider />
                    <div>
                        <form @submit.prevent="LoginHandler" name="form-login">
                            <!-- login -->
                            <div class="flex flex-column p-fluid w-full" v-if="action.current == action.values[0]">
                                <div class="field">
                                    <label for="username" class="block text-700">Usuário:</label>
                                    <span class="p-input-icon-left">
                                        <i class="pi pi-user" />
                                        <InputText id="username" type="text" placeholder="Seu login de acesso" v-model="login.username" :disabled="loading" />
                                    </span>
                                </div>
                                <div class="field">
                                    <label for="password" class="block text-700">Senha:</label>
                                    <Password id="password" v-model="login.password" placeholder="Sua senha de acesso" class="p-inputtext-sm h-3rem" :disabled="loading" :feedback="false" toggleMask />
                                </div>
                                <div class="flex align-items-center justify-content-between mb-5 gap-5">
                                    <a class="font-medium no-underline ml-2 text-right cursor-pointer" style="color: var(--primary-color)" @click="ForgotPassword()">Esqueci minha senha</a>
                                </div>
                            </div>
                            <!-- -->

                            <!-- quick validation -->
                            <div class="mb-5" v-if="action.current == action.values[1]">
                                <div class="field">
                                    <label for="txID" class="block text-700">CPF/CNPJ:</label>
                                    <span class="p-input-icon-left w-full">
                                        <i class="pi pi-user" />
                                        <InputText id="txID" type="text" class="w-full" autocomplete="off" placeholder="Seu CPF/CNPJ" v-model="login.txID" :disabled="loading" />
                                    </span>
                                </div>
                                <div class="field">
                                    <label for="password" class="block text-700">Chave de verificação:</label>
                                    <span class="p-input-icon-left w-full">
                                        <i class="pi pi-key" />
                                        <InputText id="password" type="password" autocomplete="off" v-model="login.totpCode" placeholder="Chave de verificação gerada pelo app" class="w-full" :disabled="loading" />
                                    </span>
                                </div>
                            </div>
                            <!-- -->

                            <div class="flex flex-row" v-if="action.current == action.values[0] && !loading">
                                <Button label="Validação rápida" icon="fas fa-chevron-right" iconPos="right" @click="action.current = action.values[1]" class="w-full text-base mr-3 p-button-raised p-button-outlined p-button-rounded"></Button>
                                <Button label="Login" type="submit" class="w-full p-3 w-full text-base p-button-raised p-button-outlined p-button-rounded"></Button>
                            </div>
                            <div class="flex flex-row" v-if="action.current == action.values[1] && !loading">
                                <Button label="Voltar" icon="fas fa-chevron-left" @click="action.current = action.values[0]" class="w-full text-base mr-3 p-button-raised p-button-outlined p-button-rounded"></Button>
                                <Button label="Continuar" icon="fas fa-chevron-right" iconPos="right" @click="TOTPAuthentication()" class="w-full p-3 w-full text-base p-button-raised p-button-outlined p-button-rounded"></Button>
                            </div>
                            <div class="font-medium text-lg text-bluegray-800" v-if="loading">
                                <div class="flex flex-row justify-content-center text-center" style="gap: 5px">
                                    <i class="pi pi-spin pi-spinner text-2xl" />
                                    <span>Autenticando...</span>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div class="footer justify-content-center align-items-center">
            <span class="text-sm text-900 font-light mb-1">Copyright © 2024 | IR Tecnologia - Todos os direitos reservados</span>
            <span class="text-xs text-900 font-light mb-2">Sistemas Operacionais, Gerencias e Estratégicos (S.O.G.E)</span>
        </div>

        <Toast position="bottom-right" />
    </div>
</template>

<style scoped>
/* default */
.responsive {
    width: 32rem;
    border-radius: 20px !important;
}

/* mobile small */
@media screen and (max-width: 320px) {
    .responsive {
        width: 20rem;
    }
}

/* mobile medium */
@media screen and (max-width: 480px) {
    .responsive {
        width: 30rem;
    }
}

.no-scroll-container {
    overflow: hidden;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: transparent;
    text-align: center;
    padding: 10px 0;
}

.footer span {
    display: block;
    text-align: center;
}
</style>
