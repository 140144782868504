import { UserInfo } from "./StorageKeys";
import AuthRouter from '@/api/routers/Auth'

const AuthApi = new AuthRouter();

export default async function LoadApplicationData() {
    if (!localStorage.getItem(UserInfo)) {
        let data = await AuthApi.authInfo()
            .then((resp) => {
                return {
                    menus: resp.data.userInfo.role.menus,
                    loggedInUsername: resp.data.userInfo.username,
                    userInfo: resp.data.userInfo,
                }
            })
        let settings = {
            user: {
                username: data.userInfo?.username,
                telegramNotification: data.userInfo?.telegramNotification,
                person: {
                    name: data.userInfo?.person?.name,
                    email: data.userInfo?.person?.email,
                    phone: data.userInfo?.person?.phone,
                    birthDate: data.userInfo?.person?.birthDate
                },
                roles: {
                    role: data.userInfo.role.slug,
                    indexPage: data.userInfo.role.indexPage.action
                },
                menus: data.menus,
            },
        };
        localStorage.setItem(UserInfo, JSON.stringify(settings));
        return data
    } else {
        let data = localStorage.getItem(UserInfo);
        let appCache = JSON.parse(data);
        return {
            loggedInUsername: appCache.user.username,
            userInfo: appCache.user,
        }
    }
}