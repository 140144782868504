import axios from 'axios';

const BACKEND_HOST = process.env.VUE_APP_BACKEND_HOST
const SSL_VERIFY = process.env.VUE_APP_SSL_VERIFY

const https = require('https')
const agent = new https.Agent({
    rejectUnauthorized: (SSL_VERIFY == 'true'),
})


const API = `${BACKEND_HOST}/api/v1`


const AxiosInstance = axios.create({
    baseURL: API,
    headers: {
        'Content-Type': 'application/json',
    },
    responseType: 'json',
    httpAgent: agent,
});
 
export default AxiosInstance;