<script>
import { FilterMatchMode } from "primevue/api";
import { defineComponent } from "vue";
import FilterModal from "../../Utils/Filter.vue";
import ProgressModal from "../../Utils/Progress.vue";
import UserModal from "./UserModal.vue";
import { moment } from "moment";
import UserRouter from "@/api/routers/User";
export default defineComponent({
  components: {
    FilterModal,
    ProgressModal,
    UserModal,
  },
  data() {
    return {
      loading: false,
      pagination: {},
      pageRows: 10,
      filterModal: {
        show: false,
        showFilters: ["userStatus", "companies", "people"],
        selectedFilters: {},
      },
      hasFilters: false,
      users: {
        records: [],
        totalRecords: 0,
        selection: [],
      },
      userModal: {
        show: false,
        save: false,
        valid: false,
        progress: false,
        action: "create",
        title: null,
        user: {},
      },
      filters: {
        name: {
          value: "",
          matchMode: "contains",
        },
      },
      UserApi: null,
    };
  },
  created() {
    this.UserApi = new UserRouter();
    this.FilterInit();
  },
  mounted() {
    this.pageRows = this.$refs.dt.rows;
    this.LoadUsers();
  },
  methods: {
    ShowUserModal(action, slot) {
      this.userModal.action = action;
      this.userModal.user = action == "update" ? { ...slot } : null;
      this.userModal.title = `${
        action == "create" ? "Cadastrar" : "Atualizar"
      } usuário`;
      this.userModal.show = true;
    },
    Valid(event) {
      event ? (this.userModal.valid = true) : (this.userModal.valid = false);
    },
    ConfirmUserSwitch(slot, enable) {
      if (slot.ldapAuth) {
        this.LdapInvalidOperation();
        return;
      }
      if (!enable) {
        this.$confirm.require({
          header: "Desativar usuário",
          message: "Confirma a desativação do usuário?",
          icon: "far fa-question-circle",
          acceptLabel: "Sim",
          rejectLabel: "Não",
          acceptClass: "p-button-danger",
          accept: () => {
            this.UserSwitch(slot);
          },
        });
      } else {
        this.UserSwitch(slot);
      }
    },
    UserSwitch(slot) {
      this.UserApi.Switch(slot.id)
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: "Sucesso",
            detail: "Operação realizada com sucesso",
            life: 3000,
          });
        })
        .finally(this.LoadUsers());
    },
    UserModalResetState() {
      this.userModal.show = false;
      this.userModal.valid = false;
      this.userModal.save = false;
      this.userModal.user = {};
      this.LoadUsers();
    },
    UsersRemove() {
      if (this.users.selection.find((u) => u.ldapAuth == true)) {
        this.LdapInvalidOperation();
        return;
      }
      this.$confirm.require({
        header: "Excluir",
        message:
          "Ao realizar a exclusão não será possível desfazer a operação, deseja continuar?",
        icon: "far fa-question-circle",
        acceptLabel: "Sim",
        rejectLabel: "Não",
        acceptClass: "p-button-danger",
        accept: () => {
          this.UserApi.Remove(this.users.selection.map((u) => { return u.id; }))
            .then(() => {
              this.$toast.add({
                severity: "success",
                summary: "Sucesso",
                detail: "Operação realizada com sucesso",
                life: 3000,
              });
            })
            .finally(this.LoadUsers());
        },
      });
    },
    LoadUsers() {
      this.loading = true;
      setTimeout(() => {
        this.UserApi.Search(JSON.stringify(this.pagination))
          .then((resp) => {
            this.users.records = resp.users;
            this.users.totalRecords = resp.totalRecords;
          })
          .finally((this.loading = false));
      }, Math.random() * 1000 + 250);
    },
    CloseFilterModal() {
      this.filterModal.show = false;
    },
    ShowFilterModal() {
      this.filterModal.show = true;
    },
    MakeFilter(filter) {
      this.filterModal.selectedFilters = Object.assign(
        {},
        this.filterModal.selectedFilters,
        filter
      );
      this.filters.global = Object.assign({}, this.filters.global, filter);
    },
    ClearFilters() {
      this.filterModal.selectedFilters = [];
      this.FilterInit();
      this.hasFilters = false;
      this.OnFilter();
    },
    FilterSearch() {
      this.filterModal.show = false;
      this.OnFilter();
    },
    OnPage(event) {
      this.pagination = event;
      this.LoadUsers();
    },
    OnFilter() {
      if (Object.keys(this.filterModal.selectedFilters).length > 0) {
        this.hasFilters = true;
      }
      if (this.filters.global?.referenceDate != null) {
        this.filters.global.referenceDate = moment(
          this.filters.global.referenceDate
        ).format("MM/YY");
      }
      this.pagination.filters = this.filters;
      this.LoadUsers();
    },
    LdapInvalidOperation() {
      this.$toast.add({
        severity: "error",
        summary: "Operação inválida",
        detail:
          "Usuários que efetuam login via LDAP não podem ser excluidos ou desativados",
        life: 3000,
      });
    },
    NewUserPassword(slot) {
      this.$confirm.require({
        message: `Uma nova senha sera enviada para: ${slot.person.email}\ndeseja continuar?`,
        header: "Gerar nova senha",
        icon: "far fa-question-circle",
        acceptLabel: "Sim",
        rejectLabel: "Não",
        accept: () => {
          this.UserApi.GenerateNewPassword(slot.id).then(() => {
            this.$toast.add({
              severity: "success",
              summary: "Sucesso",
              detail: `Senha enviada para: ${slot.person.email}`,
              life: 3000,
            });
          })
        },
      });
    },
    FilterInit() {
      this.pagination = {
        page: 1,
        rows: this.pageRows,
      };
      this.filters = {
        global: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
      };
    },
  },
});
</script>

<template>
  <Card>
    <template #title>
      <span class="font-light text-700 text-xl">Usuários</span>
    </template>
    <template #content>
      <Toolbar class="h-6rem">
        <template v-slot:start>
          <div class="lg:mt-0 sm:mt-2 flex flex-row" style="gap: 0.5rem" >
            <Button
              icon="fas fa-plus"
              class="p-button-rounded p-button-success p-button-outlined p-button-raised"
              @click="ShowUserModal('create')"
              :disabled="loading"
              title="Adicionar novo usuário"
            />
            <Button
              icon="fas fa-trash"
              class="p-button-rounded p-button-danger p-button-outlined p-button-raised"
              @click="UsersRemove()"
              :disabled="users.selection.length == 0"
              title="Excluir"
            />
          </div>
        </template>
        <template v-slot:end>
          <div class="lg:mt-0 sm:mt-2 flex flex-row" style="gap: 0.5rem" >
            <Button
              icon="fas fa-redo-alt"
              class="p-button-rounded p-button-info p-button-outlined p-button-raised"
              @click="LoadUsers()"
              :disabled="loading"
              title="Atualizar lista"
            />
            <div class="relative">
              <Button
                icon="fas fa-filter"
                class="p-button p-button-rounded p-button-info p-button-outlined p-button-raised"
                @click="ShowFilterModal()"
                :disabled="loading"
                title="Escolher filtros"
              />
              <Button
                v-if="hasFilters"
                icon="fas fa-broom"
                class="p-button-rounded p-button-raised clear-filter"
                title="Limpar filtros"
                @click="ClearFilters()"
                :disabled="loading"
              />
            </div>
          </div>
        </template>
      </Toolbar>
    </template>
    <template #footer>
      <DataTable
        ref="dt"
        :value="users.records"
        dataKey="id"
        :paginator="true"
        :rows="10"
        v-model:filters="filters"
        :loading="loading"
        :totalRecords="users.totalRecords"
        @page="OnPage($event)"
        @filter="OnFilter($event)"
        @sort="onSort($event)"
        lazy="true"
        v-model:selection="users.selection"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 20, 50, 100, 300]"
        currentPageReportTemplate="Exibindo de {first} a {last} de {totalRecords} entradas"
        responsiveLayout="scroll"
        class="p-datatable-sm"
      >
        <template #header />
        <Column
          selectionMode="multiple"
          headerStyle="width:.2%; min-width:1rem;"
        />
        <Column
          field="id"
          header="ID"
          :sortable="false"
          headerStyle="width:1%; min-width:2rem;"
        >
          <template #body="slotProps">
            <span class="p-column-title">ID</span>
            <span>{{ slotProps.data?.id }}</span>
          </template>
        </Column>
        <Column
          field="name"
          header="Nome"
          :sortable="false"
          headerStyle="width:6%; min-width:2rem;"
        >
          <template #body="slotProps">
            <span class="p-column-title">Nome</span>
            <span>{{ slotProps.data?.person?.name }}</span>
          </template>
        </Column>
        <Column
          field="username"
          header="Usuário"
          :sortable="false"
          headerStyle="width:2%; min-width:2rem;"
        >
          <template #body="slotProps">
            <span class="p-column-title">Usuário</span>
            <span>{{ slotProps.data.username }}</span>
          </template>
        </Column>
        <Column
          field="accessLevel"
          header="Função"
          :sortable="false"
          headerStyle="width:1%; min-width:2rem;"
        >
          <template #body="slotProps">
            <span class="p-column-title">Função</span>
            <span>{{ slotProps.data?.role?.name }}</span>
          </template>
        </Column>
        <Column
          field="enabled"
          header="Ativo"
          :sortable="false"
          headerStyle="width:1%; min-width:2rem;"
        >
          <template #body="slotProps">
            <span class="p-column-title">Ativo</span>
            <span
              v-if="slotProps.data.active"
              class="ml-2"
              title="Usuário ativo"
            >
              <i class="fas fa-check-circle fa-xl text-green-600" />
            </span>
            <span v-else class="ml-2" title="Usuário desativado">
              <i class="fas fa-times-circle fa-xl text-red-600" />
            </span>
          </template>
        </Column>
        <Column
          field="authenticationMethod"
          header="LDAP"
          :sortable="false"
          headerStyle="width:2%; min-width:2rem;"
        >
          <template #body="slotProps">
            <span class="p-column-title">LDAP</span>
            <span v-if="slotProps.data.ldapAuth" class="ml-2">
              <i class="fas fa-check-circle fa-xl text-green-600" />
            </span>
            <span v-else class="ml-2">
              <i class="fas fa-times-circle fa-xl text-red-600" />
            </span>
          </template>
        </Column>
        <Column headerStyle="width: 2%; min-width:5rem;">
          <template #body="slotProps">
            <div class="flex flex-row">
              <Button
                icon="fas fa-pencil"
                class="p-button-rounded p-button-sm p-button-warning p-button-outlined p-button-raised mr-2 action-button"
                title="Editar usuário"
                @click="ShowUserModal('update', slotProps.data)"
              />
              <Button
                icon="fas fa-key"
                class="p-button-rounded p-button-sm p-button-info p-button-outlined p-button-raised mr-2 action-button"
                title="Gerar nova senha de acesso"
                :disabled="slotProps.data.ldapAuth"
                @click="NewUserPassword(slotProps.data)"
              />
              <Button
                v-if="slotProps.data.active"
                icon="fas fa-times"
                class="p-button-rounded p-button-sm p-button-danger p-button-outlined p-button-raised mr-2 action-button"
                title="Desativar usuário"
                :disabled="slotProps.data.ldapAuth"
                @click="ConfirmUserSwitch(slotProps.data, false)"
              />
              <Button
                v-else
                icon="fas fa-check"
                class="p-button-rounded p-button-sm p-button-success p-button-outlined p-button-raised mr-2 action-button"
                title="Ativar usuário"
                :disabled="slotProps.data.ldapAuth"
                @click="ConfirmUserSwitch(slotProps.data, true)"
              />
            </div>
          </template>
        </Column>
      </DataTable>
    </template>
  </Card>
  <Dialog
    v-model:visible="userModal.show"
    :header="!userModal.progress ? userModal.title : 'Aguarde...'"
    :modal="true"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '30vw' }"
    :maximizable="false"
    :closable="!userModal.progress"
    @hide="UserModalResetState()"
  >
    <UserModal
      v-if="!userModal.progress"
      :user="userModal.user"
      :action="userModal.action"
      :save="userModal.save"
      @valid="Valid($event)"
      @progress="userModal.progress = $event"
      @close="userModal.show = false"
    />
    <ProgressModal v-if="userModal.progress" />
    <template #footer>
      <div
        class="flex flex-row justify-content-between"
        v-if="!userModal.progress"
      >
        <Button
          label="Cancelar"
          iconPos="right"
          icon="pi pi-times"
          autofocus
          @click="userModal.show = false"
          class="w-12rem"
        />
        <Button
          label="Salvar"
          iconPos="right"
          icon="pi pi-check"
          autofocus
          :disabled="!userModal.valid"
          @click="userModal.save = true"
          class="w-12rem"
        />
      </div>
    </template>
  </Dialog>
  <Dialog
    v-model:visible="filterModal.show"
    header="Filtro avançado"
    :modal="true"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '30vw' }"
    :maximizable="false"
  >
    <FilterModal
      @filterSelection="MakeFilter($event)"
      :showFilters="filterModal.showFilters"
      :currentFilters="filterModal.selectedFilters"
    />
    <template #footer>
      <div class="flex flex-row justify-content-between">
        <Button
          label="Cancelar"
          iconPos="right"
          icon="pi pi-times"
          autofocus
          @click="CloseFilterModal()"
          class="w-12rem"
        />
        <Button
          label="Pesquisar"
          iconPos="right"
          icon="pi pi-search"
          autofocus
          @click="FilterSearch()"
          class="w-12rem"
        />
      </div>
    </template>
  </Dialog>

</template>
