import AxiosInstance from './AxiosInstance';
import emitter from './Emitter';
import Token from './Token';
import AuthRouter from '@/api/routers/Auth';
import router from '@/router';

const AuthApi = new AuthRouter();
const loginEndpoint = '/api/v1/auth/login';

var serverError = false;

const resetServerError = () => {
    serverError = false;
};

const emitterToast = (toast) => {
    emitter.emit(toast.type, {
        severity: toast.event.severity,
        summary: toast.event.summary,
        detail: toast.event.detail,
        life: toast.event.life,
        group: toast.event.error
    });
};

const handleErrorResponse = (error) => {
    const status = error.response?.status;
    if (!serverError) {
        const messages = {
            400: 'Falha na operação, os dados enviados já existem, são inválidos, ou a solicitação está malformada.',
            401: 'Usuário ou senha inválidos. Verifique suas credenciais e tente novamente.',
            403: 'Você não tem permissão para acessar o conteúdo selecionado. Contate o administrador para obter acesso.',
            404: 'O conteúdo que você tentou acessar não existe ou pode estar indisponível no momento. Verifique o URL ou tente novamente mais tarde.',
            500: 'O servidor encontrou um erro interno e não pode processar esta solicitação no momento. Por favor, acione o suporte.',
            501: 'Esta funcionalidade ainda não está implementada no servidor. Por favor, acione o suporte.',
            502: 'Resposta inválida de um servidor upstream. Por favor, acione o suporte.',
            503: 'O serviço está temporariamente indisponível. Por favor, tente novamente mais tarde ou acione o suporte.',
            504: 'Tempo limite de espera atingido, o servidor upstream não respondeu a tempo. Por favor, tente novamente mais tarde ou acione o suporte.'
        };

        const message = messages[status] || error.message;
        const severity = (status >= 400 && status <= 499) ? 'warn' : 'error';
        const summary = (status >= 400 && status <= 499) ? 'Atenção' : 'Erro';

        emitterToast({
            type: 'error',
            event: {
                severity: severity,
                summary: summary,
                detail: message,
                life: 5000,
                group: 'error'
            }
        });
        
        if (status >= 500) {
            serverError = true;
        }
    }
};

const handleAuthError = async (originConfig, store) => {
    if (originConfig._retry === undefined) {
        originConfig._retry = 0;
    }

    originConfig._retry++;

    if (originConfig._retry <= 3) {
        try {
            const item = await AuthApi.refresh({ refreshToken: Token.getRefreshToken() });
            if (item?.success) {
                Token.setAuthorizationToken(item.data?.login.accessToken);
                Token.setRefreshToken(item.data?.login.refreshToken);
                store.dispatch('auth/refresh', item.data?.login.refreshToken);
                originConfig._retry = 0;
                return AxiosInstance(originConfig);
            } else {
                store.dispatch('auth/logout');
                router.push('/auth/login');
                return Promise.reject(new Error('Refresh token failed'));
            }
        } catch (_error) {
            store.dispatch('auth/logout');
            return Promise.reject(_error);
        }
    } else {
        emitterToast('error', 'error', 'Login inválido', 'Número máximo de tentativas de refresh excedido.');
        store.dispatch('auth/logout');
        return Promise.reject(new Error('Número máximo de tentativas de refresh excedido'));
    }
};

const setup = (store) => {
    AxiosInstance.interceptors.request.use(
        (config) => {
            const token = Token.getAuthorizationToken();
            if (token) {
                config.headers['Authorization'] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => Promise.reject(error)
    );

    AxiosInstance.interceptors.response.use(
        (response) => {
            resetServerError();
            return response;
        },
        async (error) => {
            const originConfig = error.config;
            if (originConfig.url !== loginEndpoint && error.response) {
                if (error.response.status === 401 && Token.getAuthorizationToken()) {
                    return handleAuthError(originConfig, store);
                } else {
                    handleErrorResponse(error);
                }
            }
            return Promise.reject(error);
        }
    );
};

export default setup;
